import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
//import util from 'util';
import CommonTrain from '../Common/common_train';
import constClass from '../../Constants/Constants';

const Booking = (props) => {
  const { user, refund_type } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const { book_id } = useParams();
  const [bookingData, setBookingData] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [refundTotalMax, setRfundTotalMax] = useState(0);
  const [refundTotal, setRfundTotal] = useState(null);
  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const reg_params = {
      "operator": "and",
      "where": [
        { "site_id": props.siteId },
        { "book_id": book_id }
      ]
    }
    var data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/search/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    data = await Promise.all(data.map(item => ({
      ...item,
      fare_bringin_total: Number(item.count_bringin) * Number(item.fare_bringin),//持ち込み料
      fare_total: item.book_type === constClass.TRAIN_BOOK_TYPE.ALL ? Number(item.total) - (Number(item.count_bringin) * Number(item.fare_bringin)) : 0,//普通運賃
    })));
    const refund_max = (!refund_type || Number(data[0].refund_total)) > 0 ? 0 : data.reduce((sum, item) => sum += ((constClass.STATUS_NFIN.includes(item.status) && constClass.STATUS.UPAY !== item.status) ? Number(item.total) : 0), 0);
    setBookingData(data);
    setRfundTotalMax(refund_max);
    if (data[0].refund_total) setRfundTotal(Number(data[0].refund_total));

    const param = {
      site_id: props.siteId,
      id: Number(book_id)
    }
    const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/payment_history`, param, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })).data;
    if (payment_data.payment) {
      setPaymentHistory(payment_data.payment);
    }
  }, [props.siteId, book_id, refund_type]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const changeRefundTotal = (e) => {
    try {
      const target = e.target;
      const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
      if (Number(value) > refundTotalMax) {
        alert("上限値を超えています。金額をご確認ください。");
      } else {
        setRfundTotal(value);
      }
    } catch (error) {
      setRfundTotal(null);
    }
  }
  const submit = async () => {
    try {
      if (refundTotal === null) {
        alert("払い戻し金額を入力してください");
        return;
      }
      if (Number(refundTotal) < 0) {
        alert("金額がマイナスになっています。");
        return;
      }
      if (Number(refundTotal) > refundTotalMax) {
        alert("上限値を超えています。金額をご確認ください。");
        return;
      }
      if (!window.confirm(`${Number(refundTotal)}円払い戻しを実行します。よろしいですか？\n※取消・再実行はできません`)) {
        return;
      }
      setLockData(true);
      //トークンを取得
      const jwt = localStorage.getItem('jwt');
      const params = {
        site_id: props.siteId,
        book_id,
        refund_total: refundTotal,
        upd_name: user.userId,
      };
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/refund/`, params, { headers: { Authorization: `Bearer ${jwt}` } });

      addToast('払い戻しを登録しました。', { appearance: 'success', autoDismiss: true });
      history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}book/input/${book_id}`);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      await refreshData();
      setLockData(false);
    }
  }
  const renderPaymentHistory = () => {
    return (
      paymentHistory.length > 0 &&
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center">決済履歴</div>
        <div className="col-11">
          <table className="table table-bordered table-striped">
            <thead className="">
              <tr>
                <th className="text-center">
                  No
                </th>
                <th className="text-center">
                  取引ID
                </th>
                <th className="text-center">
                  利用金額
                </th>
                <th className="text-center">
                  手数料等
                </th>
                <th className="text-center">
                  決済手段
                </th>
                <th className="text-center">
                  決済状況
                </th>
                <th className="text-center">
                  更新日時
                </th>
              </tr>
            </thead>
            {paymentHistory.length > 0 && (
              <tbody>
                {paymentHistory.sort((a, b) => a.history_no < b.history_no ? -1 : 1).map((data, idx) => (
                  <tr key={data.history_no}>
                    <td className="text-center">
                      {data.history_no}
                    </td>
                    <td className="text-left">
                      {data.actual_id}
                    </td>
                    <td className="text-right">
                      {Number(data.amount).toLocaleString()}
                    </td>
                    <td className="text-right">
                      {Number(data.fee).toLocaleString()}
                    </td>
                    <td className="text-center">
                      {data.payment_type}
                    </td>
                    <td className="text-center">
                      {data.job_cd_name}
                    </td>
                    <td className="text-center">
                      {moment(data.upd_date).format('YYYY-MM-DD HH:mm:SS')}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
  const renderBooking = () => {
    return (
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center">予約内容</div>
        <div className="col-11">
          <div className='row'>
            <div className='col'>
              <table className="table table-bordered text-center" height="1">
                <thead>
                  <tr>
                    <th className='align-middle'>管理<br />番号</th>
                    <th className='align-middle'>乗車日</th>
                    <th className='align-middle'>予約種類</th>
                    <th className='align-middle'>台数</th>
                    <th className='align-middle'>人数</th>
                    <th className='align-middle'>お名前</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='align-middle'>{bookingData[0].book_id}</td>
                    <td className='align-middle'>{moment(bookingData[0].book_date).format('YYYY/MM/DD')}</td>
                    <td className='align-middle'>{constClass.TRAIN_BOOK_TYPE_NAME[bookingData[0].book_type]}</td>
                    <td className='align-middle'>{bookingData[0].count_bringin} 台</td>
                    <td className='align-middle'>{bookingData[0].count_adult1 > 0 &&
                      <div className="row mx-0 pt-2 pb-1">
                        <div className="col px-0 mx-0 text-left">
                          大人
                        </div>
                        <div className="col-2 px-1 text-right text-nowrap">
                          {bookingData[0].count_adult1} 人
                        </div>
                      </div>
                    }
                      {bookingData[0].count_child1 > 0 &&
                        <div className="row mx-0 pt-2 pb-1">
                          <div className="col px-0 mx-0 text-left">
                            小児
                          </div>
                          <div className="col-2 px-1 text-right text-nowrap">
                            {bookingData[0].count_child1} 人
                          </div>
                        </div>}
                      {bookingData[0].count_adult2 > 0 &&
                        <div className="row mx-0 pt-2 pb-1">
                          <div className="col px-0 mx-0 text-left">
                            大人（障がい者または介護者）
                          </div>
                          <div className="col-2 px-1 text-right text-nowrap">
                            {bookingData[0].count_adult2} 人
                          </div>
                        </div>}
                      {bookingData[0].count_child2 > 0 &&
                        <div className="row mx-0 pt-2 pb-1">
                          <div className="col px-0 mx-0 text-left">
                            小児（障がい者または介護者）
                          </div>
                          <div className="col-2 px-1 text-right text-nowrap">
                            {bookingData[0].count_child2} 人
                          </div>
                        </div>}</td>
                    <td className='align-middle'>{bookingData[0].customer_kana}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <table className="table table-bordered text-center" height="1">
                <thead>
                  <tr>
                    <th>乗車区間</th>
                    <th>予約番号</th>
                    <th>列車番号</th>
                    <th>乗車駅</th>
                    <th>降車駅</th>
                    <th>発時間</th>
                    <th>着時間</th>
                    <th>乗車券運賃</th>
                    <th>持ち込み料</th>
                    <th>合計</th>
                    <th>ステータス</th>
                    <th>最終更新日</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingData.sort((a, b) => a.round_type - b.round_type).map((item, idx) => (
                    <tr key={idx} className={constClass.STATUS_NFIN.includes(item.status) ? "bg-white" : "bg-secondary"}>
                      <td>{bookingData.length === 1 ? "乗車区間" : item.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND ? "行き" : "帰り"}</td>

                      {(bookingData.length > 1 && item.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && !item.train_no) ?
                        <td colSpan={9}>予約なし</td>
                        :
                        <React.Fragment>
                          <td>{item.view_book_no}</td>
                          <td>{item.train_no}</td>
                          <td>{item.ride_station_name}</td>
                          <td>{item.getoff_station_name}</td>
                          <td>{CommonTrain.strTotime(item.ride_time)}</td>
                          <td>{CommonTrain.strTotime(item.getoff_time)}</td>
                          <td className='text-right text-nowrap'>{(item.fare_total).toLocaleString()}円</td>
                          <td className='text-right text-nowrap'>{(item.fare_bringin_total).toLocaleString()}円</td>
                          <td className='text-right text-nowrap'> {item.total.toLocaleString()} 円</td>
                          <td>{constClass.STATUS_NAME[item.status]}</td>
                          <td>{moment(item.upd_date).format('YY/MM/DD HH:mm')}</td>
                        </React.Fragment>
                      }
                    </tr>
                  ))}
                  {bookingData.length > 1 &&
                    <tr className='bg-gray'>
                      <td colSpan={7} className="text-right text-middle">往復計</td>
                      <td className='text-right text-nowrap'>{bookingData.reduce((sum, item) => sum += item.fare_total, 0).toLocaleString()} 円</td>
                      <td className='text-right text-nowrap'>{bookingData.reduce((sum, item) => sum += item.fare_bringin_total, 0).toLocaleString()} 円</td>
                      <td className='text-right text-nowrap'>{bookingData.reduce((sum, item) => sum += item.total, 0).toLocaleString()} 円</td>
                      <td></td>
                      <td></td>
                    </tr>
                  }
                  {Number(bookingData[0].refund_total) > 0 && <React.Fragment>
                    <tr>
                      <td colSpan={7} className="text-right text-middle">払い戻し額</td>
                      <td className='text-right'></td>
                      <td className='text-right'></td>
                      <td className='text-right'> {Number(bookingData[0].refund_total).toLocaleString()} 円</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={7} className="text-right text-middle">合計</td>
                      <td className='text-right'></td>
                      <td className='text-right'></td>
                      <td className='text-right'> {(bookingData.reduce((sum, item) => sum += item.total, 0) - Number(bookingData[0].refund_total)).toLocaleString()}円</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </React.Fragment>}
                </tbody>
              </table>
            </div>
          </div>
          {refundTotalMax > 0 &&
            <React.Fragment>
              <div className="row mx-0 pt-2 pb-1">
                <div className='col-2'>未乗車運賃</div><div className='col-2 text-right pr-3'>{bookingData.reduce((sum, item) => sum += (constClass.STATUS_PRE.includes(item.status) ? item.fare_total : 0), 0).toLocaleString()}</div><div className='col'>円</div>
              </div>
              <div className="row mx-0 pt-2 pb-1">
                <div className='col-2'>未乗車持ち込み料</div><div className='col-2 text-right pr-3'>{bookingData.reduce((sum, item) => sum += (constClass.STATUS_PRE.includes(item.status) ? item.fare_bringin_total : 0), 0).toLocaleString()}</div><div className='col'>円</div>
              </div>
              <div className="row mx-0 pt-2 pb-1">
                <div className='col-2 pt-2'>払い戻し額</div><div className='col-2 pt-2 text-right pr-3'>
                  <input type="number" className='form-control text-right' id="refund_total" name="refund_total" value={refundTotal || ''} onChange={e => changeRefundTotal(e)} />
                </div>
                <div className='col-1 pt-2'>円</div>
                <div className='col-2 pt-2 text-left'><button className='btn btn-active w-100' disabled={lockData || (refundTotalMax <= 0)} onClick={e => submit()}>払い戻し登録</button></div>
              </div>
              <div className='row'><div className='col-4 text-right'>（{refundTotalMax > 0 ? `最大値:${refundTotalMax.toLocaleString()} 円` : "払い戻し済"}）</div></div>

              <hr /></React.Fragment>}
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      {bookingData.length > 0 && renderBooking()}
      {paymentHistory.length > 0 && renderPaymentHistory()}
    </div>
  )
}

export default Booking;
import React, { useState } from 'react';
import Common from '../Components/Common/common.js';
import QRCodeScanner from '../Components/Common/QRCodeScanner';

const QRApp = (props) => {
  const [message, setMessage] = useState('');
  const [scanning, setScanning] = useState(true);

  const setQrCodeData = (url) => {
    if (Common.getCdFromQrString(url)) {
      // var winRef;
      setScanning(false);
      // window.addEventListener('blur', function () {
      //   if (winRef) {
      //     winRef.close();
      //   }
      //   window.close();
      // });
      window.open(url, '_self');
      // window.location.replace(url);
      // window.open(url, '_blank');
      // フォーカス状態になったとき(再表示されたとき)にタブを閉じる
      // document.referrer = "";
      // window.addEventListener('focus', function () {
      //   if (winRef) {
      //     winRef.close();
      //   }
      //   // window.close();
      // });
      setMessage('読み取りが完了しました。このウィンドウは5秒後に自動的に閉じられます。')
      const intervalId = window.setInterval(() => {
        window.clearInterval(intervalId);
        window.close();
      }, 5000);
    } else {
      setMessage('読み取りに失敗しました。再度読み取りをお願いします。');
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col text-center">
          <span>二次元コード読み取り</span>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <QRCodeScanner {...props} setQrCodeData={setQrCodeData} message={message} scanning={scanning} />
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <button
            className="btn btn-link text-body"
            onClick={() => window.close()}>
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
}

export default QRApp;
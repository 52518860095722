import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import QRCode from "react-qr-code";
import Common from '../Common/common';

const TrainStation = (props) => {
  const { settings } = props;
  const [stationList, setStationMaster] = useState([]);
  const [selectStation, setSelectStation] = useState({ station_cd: '', station_name: '' });
  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const stationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_station/listall`, null, { headers: { Authorization: `Bearer ${jwt}` } });
    const station = (await stationPromise).data;
    setStationMaster(station.filter(x => x.target_flg === '1'));
  }, []);

  useEffect(() => {
    refreshData();
  }, [refreshData]);
  const changeStation = (value) => {
    if (value === '') {
      setSelectStation({ station_cd: '', station_name: '' })
    } else {
      const select = stationList.find(x => x.station_cd === value);
      setSelectStation(select)
    }
  }
  const renderList = () => {
    return (
      <div>
        <div className='row'>
          <div className='col'>
            <select className="custom-select w-100 text-center" aria-label="駅"
              value={selectStation.station_cd}
              onChange={(e) => changeStation(e.target.value)}>
              <option value="" >- 選択 -</option>
              {stationList.map((item) =>
                <option key={item.station_cd} value={item.station_cd}>{item.station_name}</option>
              )}
            </select>
          </div>
        </div>
        <div className='row'>
          <div className='col text-center p-3'>
            {selectStation.station_cd !== '' && <QRCode value={`${Common.getSettingValue(settings, "API_LIFF_URL", '')}/?page=rideqr&cd=${selectStation.station_cd}`} />}
          </div>

        </div>
        {/* {stationList.map((item, idx) => (
          <div className='row' key={idx}>
            <div className='col-2'>{item.station_name}</div>
            <div className='col-3'>{item.station_cd}</div>
            <div className='col-3 py-3'><QRCode value={item.station_cd} /></div>
          </div>
        ))} */}
      </div>
    );
  }
  return (
    <div className="container">
      {(stationList.length > 0) && renderList()}
    </div>
  )
}

export default TrainStation;

var Constants = {};
//サイクルトレイン予約のサイトID
Constants.CYCLETRAIN_SITE_ID = 1;
Constants.CYCLETRAIN_LINKURL = '/cycletrain';

Constants.STATUS = {
  REG: 'REG',
  PRE: 'PRE',
  CPRE: 'CPRE',
  RDE: 'RDE',
  FIN: 'FIN',
  CCL: 'CCL',
  CUPAY: 'CUPAY',
  UPAY: 'UPAY',
  CFIN: 'CFIN'
};

//乗車/降車処理できるステータス
Constants.STATUS_RDEOK = [Constants.STATUS.REG, Constants.STATUS.PRE, Constants.STATUS.RDE];
//変更可能ステータス
Constants.STATUS_PRE = [null, Constants.STATUS.UPAY, Constants.STATUS.REG, Constants.STATUS.PRE];
//未完了扱いするステータス
Constants.STATUS_NFIN = [Constants.STATUS.UPAY, Constants.STATUS.REG, Constants.STATUS.PRE, Constants.STATUS.CPRE, Constants.STATUS.RDE];
//キャンセル扱いするステータス
Constants.STATUS_CCL = [Constants.STATUS.CCL, Constants.STATUS.CUPAY, Constants.STATUS.CFIN];

Constants.STATUS_NAME = {
  UPAY: '未決済',
  REG: '予約済',
  PRE: '事前通知済',
  CPRE: '復路乗車不可通知済',
  RDE: '乗車',
  FIN: '降車',
  CUPAY: 'キャンセル未決済',
  CCL: 'キャンセル済', //決済取消
  CFIN: 'キャンセル済(返金)',//決済一部返金
};

Constants.STATUS_NAME_USER = {
  UPAY: '未決済',
  REG: '予約済み',
  PRE: '予約済み',
  CPRE: '予約済み',
  RDE: '乗車',
  FIN: '降車',
  CUPAY: 'キャンセル未決済',
  CCL: 'キャンセル',
  CFIN: 'キャンセル'
};

Constants.STATUS_NAME_CSV = {
  UPAY: '未決済',
  REG: '未乗車',
  PRE: '未乗車',
  CPRE: '未乗車',
  RDE: '乗車',
  FIN: '完了',
  CUPAY: '未決済',
  CCL: 'キャンセル',
  CFIN: 'キャンセル(返金)'
};

Constants.PAYMENT_TYPE_CSV = {
  credit: 2,
  paypay: 3,
};

Constants.PAYMENT_TYPE_NAME_CSV = {
  credit: 'クレカ',
  paypay: 'PayPay',
};

Constants.PAPER_ORDER = 'PAPER_ORDER';

Constants.METHOD = {
};

Constants.METHOD_NAME = {
};

Constants.CLASS = {
  ADMIN: '1',
  SHOP: '2',
};

Constants.CLASS_NAME = {
  '1': '管理者',
  '2': '一般',
  ADMIN: '管理者',
  SHOP: '一般',
};

Constants.CHANNEL = {
};

Constants.SAIBAN_CLASS = {
};

Constants.MENU = "menu";
Constants.USER = "user";
Constants.BOOKMENU = "bookmenu";
Constants.INPUTALERT = "inputalert";
Constants.INPUT = "input";
Constants.INPUTBIN1 = "inputbin1";
Constants.INPUTBIN2 = "inputbin2";
Constants.CONFIRM = "confirm";
Constants.FINISH = "finish";
Constants.HISTORY = "history";
Constants.PAYMENT = "payment";

Constants.CHANGECONFIRM = "changeconfirm"
Constants.RIDEQR = "rideqr";
Constants.RIDETICKET = "rideticket"
Constants.GETOFFTICKET = "getoffticket"
Constants.CANCEL = "cancel";

Constants.OPEN = "1";
Constants.CLOSE = "2";

Constants.FLAG = {
  OFF: '0',
  ON: '1'
}

Constants.ACTIVE_BUTTONS = {
  'REG': [
    'RDE',
  ],
  'PRE': [
    'RDE',
  ],
  'RDE': [
    'FIN',
  ],
  'CCL': [],
  'FIN': [],
  'UPAY': [],
  'CUPAY': [],
};
Constants.STOP_MESSAGE_TITLE = {
  SITE1: 'サービス停止中'
}
Constants.STOP_MESSAGE = {
  SITE1: 'サービス停止中です。'
}

Constants.CUSTOMER_GENDER = [
  '1', '2', '3'
];
Constants.CUSTOMER_GENDER_NAME = {
  '1': '男性',
  '2': '女性',
  '3': 'その他',
}

Constants.CUSTOMER_AGE_TYPE = [
  '1', '2', '3', '4', '5', '6',
]
Constants.CUSTOMER_AGE_TYPE_NAME = {
  '1': '10代',
  '2': '20代',
  '3': '30代',
  '4': '40代',
  '5': '50代',
  '6': '60代以上',
}
Constants.COUNT_PASSENGER_LIST = [
  { name: 'count_adult1', text: '大人' },
  { name: 'count_child1', text: '小児' },
  { name: 'count_adult2', text: '大人(障がい者または介護者)' },
  { name: 'count_child2', text: '小児(障がい者または介護者)' },
];
/* サイクルトレイン用 */
Constants.TRAIN_BOOK_TYPE = {
  ALL: '1',
  BRINGIN: '2',
}
Constants.TRAIN_BOOK_TYPE_NAME = {
  '1': '乗車券＋持ち込み料',
  '2': '持ち込み料のみ',
  ALL: '乗車券＋持ち込み料',
  BRINGIN: '持ち込み料のみ'
}

Constants.TRAIN_ROUND_TYPE = {
  "0": '行き',
  "1": '帰り',
  OUTBOUND: "0",
  RETURNTRIP: "1",
}
Constants.TRAIN_JYOGE_TYPE = {
  NOBORI: "0",
  KUDARI: "1",
}
Constants.TRAIN_JYOGE_TYPE_NAME = {
  "0": "上り",
  "1": "下り",
  NOBORI: "上り",
  KUDARI: "下り",
}
Constants.TRAIN_DAIYA_SBT_LIST = [
  '1', '4', '5', '3'
]
Constants.TRAIN_DAIYA_SBT = {
  '1': '平日',
  '4': '土曜',
  '5': '日祝',
  '3': '正月',
  'F': '運賃'
}
Constants.TRAIN_OPERATE_NAME = {
  LIMITED_EXPRESS: '特急',
  EXPRESS: '急行',
  LOCAL: '普通',
  '1': '特急',
  '2': '急行',
  '3': '普通',
}
Constants.TRAIN_OPERATE_NAME_SHORT = {
  LIMITED_EXPRESS: '特',
  EXPRESS: '急',
  LOCAL: '普',
  '1': '特',
  '2': '急',
  '3': '普',
}


export default Constants;
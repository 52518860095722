import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import moment from 'moment';
// import { CSVReader } from 'react-papaparse'
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import Loading from '../Loading/Loading';

registerLocale('ja', ja);
// const uploadButtonRef = React.createRef();

const TrainDaiya = (props) => {
  const [uploadData, setUploadData] = useState({ kaisei_date: null, file_type: null, file_name: null, file_data: null });
  const [daiyaData, setDaiyaData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  // const daiya_csv_keys = ["DAIYA_SBT", "SUJI_NO", "EKI_CD", "TRAIN_NO", "JYOGE_TYPE", "ROSEN_CD", "EKI_ORDER", "TRAIN_ORDER", "S_EKI_CD", "E_EKI_CD", "JYOKEN_MARK", "TIME_S", "TIME_E", "IKISAKI_RYAKU", "TRAIN_SBT", "UNTEN_SBT", "NORIBA_INFO", "TIME_S2", "TIME_E2"];
  // const daiya_keys = ["daiya_sbt", "suji_no", "station_cd", "train_no", "jyoge_type", "rosen_cd", "eki_order", "train_order", "start_station_cd", "des_station_cd", "jyoken_mark", "time_s", "time_e", "ikisaki_ryaku", "train_sbt", "unten_sbt", "noriba_info", "time_s2", "time_e2"];
  // const fare_csv_keys = ["EKI_CD1", "EKI_CD2", "NORITSUGI_EKI_CD", "VIEW_KM", "CALK_KM", "UCN"];
  // const fare_keys = ["station_cd1", "station_cd2", "station_cd3", "view_km", "calc_km", "fare"];
  //トークンを取得
  const jwt = localStorage.getItem('jwt');

  const refreshDaiya = useCallback(async () => {
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/train_daiya_torikomi/`, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setDaiyaData(data);
    setUploadData({ kaisei_date: null, file_type: null, file_name: null, file_data: null });
  }, [jwt]);

  useEffect(() => {
    refreshDaiya();
  }, [refreshDaiya]);

  const changeDate = (date) => {
    setUploadData({ ...uploadData, kaisei_date: date });
  }
  const changeType = (e) => {
    const target = e.target;
    setUploadData({ ...uploadData, file_type: target.value });
  }
  const handleDaiyaDelete = async (data) => {
    if (data.file_type === "F") {
      if (!window.confirm('有効な運賃データが0件になると、LINEからの予約ができなくなります。削除しますか？')) {
        return;
      }
    } else
      if (!window.confirm('削除しますか？')) {
        return;
      }
    let res;
    try {
      setLockData(true);
      const params = {
        site_id: props.siteId,
        kaisei_date: data.kaisei_date,
        file_type: data.file_type,
        upd_date: data.upd_date
      }
      //console.log(params)
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_daiya_time/delete`, params, { headers: { Authorization: `Bearer ${jwt}` } });

      refreshDaiya();
    } catch (error) {
      console.log(util.inspect(error))
      if (error.response.data !== null) {
        addToast(error.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(error.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (!res.data.result) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました', { appearance: 'success', autoDismiss: true });
        await refreshDaiya();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  // const handleDaiyaUpload = async () => {
  //   const params = {
  //     site_id: props.siteId,
  //     kaisei_date: uploadData.kaisei_date,
  //     file_type: uploadData.file_type,
  //     data_list: uploadData.file_data,
  //     file_name: uploadData.file_name,
  //     field_keys: (uploadData.file_type === "F" ? fare_keys : daiya_keys).concat(["kaisei_date", "ins_name", "ins_date", "upd_name", "upd_date"])
  //   }
  //   console.log(params)
  //   await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_daiya_time/`, params, { headers: { Authorization: `Bearer ${jwt}` } });

  //   window.alert('アップしました');

  //   refreshDaiya();
  // }
  // const handleOnDrop = async (datalist, file) => {
  //   var list = [];
  //   var data = { kaisei_date: uploadData.kaisei_date, ins_name: 'daiya', ins_date: new Date(), upd_name: 'daiya', upd_date: new Date() }
  //   if (uploadData.file_type !== "F") {
  //     await datalist.map(item => {
  //       if (item.data[daiya_csv_keys[0]] !== "") {
  //         daiya_keys.map((k, idx) => { data = { ...data, [k]: item.data[daiya_csv_keys[idx]] }; return k; });
  //         list.push(data);
  //       }
  //       return item;
  //     });
  //   } else {
  //     await datalist.map(item => {
  //       if (item.data[fare_csv_keys[0]] !== "") {
  //         fare_keys.map((k, idx) => { data = { ...data, [k]: item.data[fare_csv_keys[idx]] }; return k; });
  //         list.push(data);
  //       }
  //       return item;
  //     });
  //   }
  //   setUploadData({ ...uploadData, file_data: list, file_name: file.name });
  // };

  // const handleOnError = (err, file, inputElem, reason) => {
  //   console.log(err);
  // };
  // const handleOnRemoveFile = () => {
  //   setUploadData({ ...uploadData, file_data: null, file_name: null });
  // }

  /* ファイルアップロード */
  const handleUploadClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.uploadfile) {
      window.alert('ファイルを選択してください。');
      return;
    }
    if (!uploadData.kaisei_date) {
      window.alert('改正日を設定してください');
      return;
    }
    if (daiyaData.findIndex(x => x.file_type === data.file_type && x.kaisei_date === uploadData.kaisei_date) >= 0) {
      if (!window.confirm('同じ改正日のデータをアップロードしようとしています。上書きしますか？')) {
        return;
      }
    }
    let res;
    try {
      setLockData(true);
      const form = new FormData();
      if (data.uploadfiletype) form.append("uploadfile", data.uploadfile);
      form.append("site_id", props.siteId);
      form.append("kaisei_date", uploadData.kaisei_date);
      form.append("file_type", uploadData.file_type);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_daiya_time/upload/`, form, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'multipart/form-data',
        }
      });

    } catch (err) {
      console.log(util.inspect(err));
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (!res.data.result) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshDaiya();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  const handleResetClick = () => {
    setUploadData({ kaisei_date: null, file_type: null, file_name: null, file_data: null });
  };

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setUploadData({
        ...uploadData,
        uploadfile: value,
        uploadfiletype: value.type,
        deletefile: uploadData.couponCodes,
        uploadfiledata: URL.createObjectURL(value),
      });
    }
    reader.readAsDataURL(value);
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-8 p-0">
          <table className="table table-bordered table-striped" height="1">
            <thead>
              <tr>
                <th className="text-center align-middle like-button">
                  改正日
                </th>
                <th className="text-center align-middle like-button">
                  ファイルタイプ
                </th>
                <th className="text-center align-middle like-button">
                  ファイル名
                </th>
                <th className="text-center align-middle like-button">
                  処理
                </th>
              </tr>
            </thead>
            <tbody>
              {daiyaData.length <= 0 && <tr><td className="text-center align-middle p-3" colSpan={6}>データが取得できませんでした</td></tr>}
              {daiyaData.map((data, idx) => (
                <tr key={idx}>
                  {/* 改正日 */}
                  <td className="text-center align-middle p-1">
                    {data.kaisei_date}
                  </td>
                  {/* 種類 */}
                  <td className="text-center align-middle p-1">
                    {constClass.TRAIN_DAIYA_SBT[data.file_type]}
                  </td>
                  {/* ファイル名 */}
                  <td className="text-center align-middle p-1">
                    {data.file_name}
                  </td>
                  <td className="text-center align-middle p-1">
                    <button className='btn btn-primary' disabled={lockData} onClick={e => { handleDaiyaDelete(data) }}>
                      削除
                    </button>
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  const renderUpload = () => {
    return (
      <React.Fragment>
        <div className="row mb-3 p-0">
          <div className="col-1 p-0">
            改正日
          </div>
          <div className="col-3 p-0">
            <DatePicker className="custom-select w-100 text-center border-textbox text-primary"
              aria_labal="利用日付" locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id="kaisei_date" name="kaisei_date"
              popperPlacement='bottom'
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
              }}
              selected={uploadData.kaisei_date ? new Date(moment(uploadData.kaisei_date, 'YYYYMMDD').format('YYYY/MM/DD')) : null}
              onChange={date => changeDate(date ? moment(date).format('YYYYMMDD') : null)}
              placeholderText="- 選択 -"
              disabledKeyboardNavigation
              customInput={<button>{uploadData.kaisei_date ? moment(uploadData.kaisei_date, 'YYYYMMDD').format('YYYY/MM/DD') : '-- 選択 --'}</button>}
            />
          </div>
        </div>
        <div className="row mb-3 p-0">
          <div className="col-1 p-0">
            曜日
          </div>
          <div className="col-3 p-0">
            <select className="custom-select w-100" aria-label="ファイル種類" id="file_type" name="file_type" value={uploadData.file_type || ''} onChange={changeType}>
              <option value="">-- 選択 --</option>
              {constClass.TRAIN_DAIYA_SBT_LIST.map((a, idx) => (<option key={idx} value={a}>{constClass.TRAIN_DAIYA_SBT[a]}</option>)
              )}
              <option value="F">運賃</option>
            </select>
          </div>
        </div>
        <div className="row mb-3 p-0">
          <div className="col-1 p-0">
            ファイル
          </div>
          <div className="col p-0">
            {/*
            <CSVReader
              ref={uploadButtonRef}
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              config={{ header: true, encoding: "shift-jis" }}
            >
              <span>ダイヤデータCSVファイルを選択してください</span>
            </CSVReader>
            <button onClick={handleDaiyaUpload}>
              アップロード
            </button>
          */}
            <div className="col-4 align-self-center">
              {!(uploadData.uploadfile) && <input type="file" className="form-control-file" name="couponCodes" id="couponCodes" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
              {uploadData.uploadfile && uploadData.uploadfile.name}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <button type="button" className='btn btn-active' disabled={lockData || !uploadData.uploadfile} onClick={() => handleUploadClick(uploadData)}>
              アップロード
            </button>
          </div>
          <div className='col-4'>
            <button type="button" className='btn btn-primary' onClick={() => handleResetClick()}>
              リセット
            </button>
          </div>
        </div>
      </React.Fragment>);
  }
  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(daiyaData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(props.user)}</div></div>
      {daiyaData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {daiyaData !== null && renderList()}
      {renderUpload()}
      {lockData && <Loading />}
    </div>
  )
}

export default TrainDaiya;
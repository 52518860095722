import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Common from '../Common/common';
import CommonTrain from '../Common/common_train';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import util from 'util';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';

const TrainBinAllStop = (props) => {
  const { user, settings } = props;
  const [searchData] = useState({
    book_start: moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD'),
    book_end: moment().add(2, 'month').endOf('month').format('YYYY/MM/DD'),
  });
  const [bookDate, setBookDate] = useState(moment().format("YYYY/MM/DD"));
  const [stopReason, setStopReason] = useState("");
  const [calendarList, setCalendarList] = useState([]);
  const [binListData, setBinListData] = useState(null);

  const refreshMaster = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.siteId,
      book_start: searchData.book_start,
      book_end: searchData.book_end,
    }
    const calendarPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const calendar = (await calendarPromise).data;
    setCalendarList(calendar);

  }, [props.siteId, searchData]);

  const refreshData = useCallback(async () => {
    try {
      //トークンを取得
      const jwt = localStorage.getItem('jwt');
      const params = {
        site_id: props.siteId,
        book_start: moment(bookDate).format('YYYY-MM-DD'),
        book_end: moment(bookDate).format('YYYY-MM-DD'),
      }
      const trainBinPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/search/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
      const trainbin = (await trainBinPromise).data;
      trainbin.sort((a, b) => a.start_ride_time - b.start_ride_time);
      trainbin.forEach((data, idx) => {
        let nowTime = moment().add(10, 'm').format('YYYYMMDDHHmm');  //10分後
        let maxRideTime = moment(data.book_date).format('YYYYMMDD') + ("0" + data.max_ride_time).slice(-4);
        data.status_message = maxRideTime <= nowTime ? '受付終了' : '受付中';
      });
      setBinListData(trainbin);
    } catch (error) {
      console.log(util.inspect(error));
      setBinListData(null);
    }
  }, [props.siteId, bookDate]);

  const stopReasonItem = () => {
    const items = [];
    var list = JSON.parse(Common.getSettingValue(settings, "STOP_REASON_LIST", []));
    items.push(<option value="" key={"-"}>- 停止理由を選択してください。 -</option>)
    list.forEach((data, idx) => {
      items.push(<option value={data} key={idx}>{data}</option>)
    });
    return items;
  }
  const handleAllRestartClick = async () => {
    const jwt = localStorage.getItem('jwt');
    if (window.confirm(`${moment(bookDate).format('YYYY年MM月DD日')} の予約受付を再開しますか？`)) {
      var data = {
        site_id: props.match.params.siteId,
        book_date: moment(bookDate).format("YYYY-MM-DD"),
        book_stop_message: stopReason,
        upd_name: user.userId,
      };
      var res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/restart/`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res.data.error) {
        window.alert(res.data.message);
      } else {
        window.alert(`${moment(bookDate).format('YYYY年MM月DD日')} の予約受付を再開しました`);
        await refreshData();
      }
    }
  }
  const handleAllStopClick = async () => {
    const jwt = localStorage.getItem('jwt');
    if (window.confirm(`${moment(bookDate).format('YYYY年MM月DD日')} の予約受付を全停止しますか？`)) {
      var data = {
        site_id: props.match.params.siteId,
        book_date: moment(bookDate).format("YYYY-MM-DD"),
        book_stop_message: stopReason,
        upd_name: user.userId,
      };
      var res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/allstop/`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (!res.data.result) {
        window.alert(res.data.message);
      } else {
        window.alert(`${moment(bookDate).format('YYYY年MM月DD日')} の予約受付を全停止しました`);
        if (res.data.message) {
          window.alert('予約のキャンセル時に以下のエラーが発生しました。予約一覧を確認してください。\n' + res.data.message);
        }
        await refreshData();
      }
    }
  }

  const handleChangeStopReason = (e) => {
    const target = e.target;
    setStopReason(target.value);
  }
  const handleChangeBookDate = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    setBookDate(value);
  }
  const checkInvalid = () => {
    return !bookDate || !stopReason || !binListData || binListData.filter((d) => d.book_status === constClass.FLAG.ON && d.status_message === '受付中').length === 0;
  }

  useEffect(() => {
    refreshMaster();
  }, [refreshMaster]);

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await refreshData();
      intervalId = setInterval(() => {
        refreshData();
      }, 60000);
    }
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshData]);

  const renderHeader = () => {
    return (
      <div className="mx-0">
        <div className="row mb-3">
          <div className="col-1 text-center align-self-center">
            <span>利用日付</span>
          </div>
          <div className="col-2 text-center align-self-center">
          <select className="custom-select w-100 text-center border-textbox text-primary"
              value={bookDate}
              id="bookDate" name="bookDate" onChange={handleChangeBookDate}>
              <option value="" >- 選択 -</option>
              {calendarList.map((item, idx) =>
                <option key={idx} value={moment(item.book_date).format('YYYY/MM/DD')}>{moment(item.book_date).format('YYYY/MM/DD')}({moment(item.book_date).format('dd')})</option>
              )}
            </select>
          </div>
          <div className="col-1 text-center align-self-center">
            <span>停止理由</span>
          </div>
          <div className="col-4 text-center align-self-center">
            <select className="custom-select w-100" aria-label="停止理由" id="stop_reason" name="stop_reason" value={stopReason} onChange={handleChangeStopReason}>
              {stopReasonItem()}
            </select>
          </div>
          <div className="col-2 p-0 text-right align-self-center">
            <button disabled={checkInvalid()} className="btn btn-danger" onClick={handleAllStopClick}>予約全停止</button>
          </div>
          {(binListData && binListData.length > 0 && binListData.findIndex(x => x.book_status === constClass.FLAG.OFF) >= 0) &&
            <div className="col-2 p-0 text-right align-self-center">
              <button className="btn btn-primary" onClick={handleAllRestartClick}>予約再開</button>
            </div>}
        </div>
      </div>
    )
  }

  const renderList = () => {
    return (
      <div className="print-list">
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped" height="1">
              <thead>
                <tr>
                  <th className="text-center align-middle like-button">
                    列車番号
                  </th>
                  <th className="text-center align-middle like-button">
                    上下区分
                  </th>
                  <th className="text-center align-middle like-button">
                    運行開始時刻
                  </th>
                  <th className="text-center align-middle like-button">
                    ステータス
                  </th>
                </tr>
              </thead>
              <tbody>
                {binListData.length === 0 && <tr><td colSpan={4}>便がありません。</td></tr>}
                {binListData.map((bin, idx) => (
                  <tr className={bin.book_status === constClass.FLAG.OFF ? `status-stop` : bin.status_message === '受付終了' ? `status-end` : ``} key={idx}>
                    <td>
                      <Link to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}trainbin/${moment(bookDate).format('YYYYMMDD')}/${bin.train_no}`}>{bin.train_no}</Link>
                    </td>
                    <td>
                      {constClass.TRAIN_JYOGE_TYPE_NAME[bin.jyoge_type]}
                    </td>
                    <td>
                      {CommonTrain.strTotime(bin.start_ride_time)}
                    </td>
                    <td>
                      {bin.book_status === constClass.FLAG.OFF ? bin.book_stop_message : bin.status_message}
                    </td>
                  </tr>
                )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      {renderHeader()}
      {binListData !== null && renderList()}
    </div>
  )
}

export default TrainBinAllStop;
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import constClass from '../Constants/Constants';

// Stateの初期状態
const initialState = {
  book_start: moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD'),
  book_end: moment().add(2, 'month').endOf('month').format('YYYY/MM/DD'),
  station: '',
  status: constClass.STATUS_NFIN,
  bookDate: '',
  stationList: [],
  initFlag: false,
};

const TrainBookingCondition = createSlice({
  name: "TrainBookingCondition",
  initialState: initialState,
  reducers: {
    setSearchData: (state, action) => ({
      book_start: action.payload.book_start,
      book_end: action.payload.book_end,
      station: action.payload.station,
      status: action.payload.status,
      bookDate: state.bookDate,
      stationList: state.stationList,
      initFlag: state.initFlag,
    }),
    setBookDate: (state, action) => ({
      book_start: state.book_start,
      book_end: state.book_end,
      station: state.station,
      status: state.status,
      bookDate: action.payload,
      stationList: state.stationList,
      initFlag: state.initFlag,
    }),
    setBookStart: (state, action) => ({
      book_start: action.payload,
      book_end: state.book_end,
      station: state.station,
      status: state.status,
      bookDate: state.bookDate,
      stationList: state.stationList,
      initFlag: state.initFlag,
    }),
    setBookEnd: (state, action) => ({
      book_start: state.book_start,
      book_end: action.payload,
      station: state.station,
      status: state.status,
      bookDate: state.bookDate,
      stationList: state.stationList,
      initFlag: state.initFlag,
    }),
    setStation: (state, action) => ({
      book_start: state.book_start,
      book_end: state.book_end,
      station: action.payload,
      status: state.status,
      bookDate: state.bookDate,
      stationList: state.stationList,
      initFlag: state.initFlag,
    }),
    setStatus: (state, action) => ({
      book_start: state.book_start,
      book_end: state.book_end,
      station: state.station,
      status: action.payload,
      bookDate: state.bookDate,
      stationList: state.stationList,
      initFlag: state.initFlag,
    }),
    setStationList: (state, action) => ({
      book_start: state.book_start,
      book_end: state.book_end,
      station: state.station,
      status: state.status,
      bookDate: state.bookDate,
      stationList: action.payload,
      initFlag: state.initFlag,
    }),
    init: (state) => ({
      book_start: state.book_start,
      book_end: state.book_end,
      station: state.station,
      status: state.status,
      bookDate: state.bookDate,
      stationList: state.stationList,
      initFlag: true,
    }),
    logout: (state) => ({
      ...initialState,
    })
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { setSearchData, setBookDate, setBookStart, setBookEnd, setStation, setStatus, setStationList, init, logout } = TrainBookingCondition.actions;

export default TrainBookingCondition;
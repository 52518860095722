import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from 'axios';
//import util from 'util';
import constClass from '../../Constants/Constants';
import moment from 'moment';
//import DatePicker, { registerLocale } from "react-datepicker"
//import "react-datepicker/dist/react-datepicker.css"
//import ja from 'date-fns/locale/ja';
import TrainBookingList from "./TrainBookingList";

//registerLocale('ja', ja);
const TrainBookingListTab = (props) => {
  const { condition, conditionActions } = props;
  // const [searchData, setSearchData] = useState({
  //   book_start: moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD'),
  //   book_end: moment().add(2, 'month').endOf('month').format('YYYY/MM/DD'),
  //   station: props.user.userClass !== constClass.CLASS.ADMIN ? ('0000' + props.user.shopId).slice(-4) : '',
  //   status: constClass.STATUS_NFIN,
  // });
  // const [bookDate, setBookDate] = useState(null);
  const statusIndex = (status) => {
    if (status === constClass.STATUS_NFIN) {
      return 0;
    } else if (JSON.stringify(status) === JSON.stringify([constClass.STATUS.FIN])) {
      return 1;
    } else if (status === constClass.STATUS_CCL) {
      return 2;
    } else {
      return 0;
    }
  };
  const [calendarList, setCalendarList] = useState([]);
  // const [stationList, setStationMaster] = useState([]);

  const [index, setIndex] = useState(statusIndex(condition.status));
  const handleTabSelect = (idx, lastIndex, e) => {
    setIndex(idx);
    conditionActions.setStatus(idx === 2 ? constClass.STATUS_CCL : idx === 1 ? [constClass.STATUS.FIN] : constClass.STATUS_NFIN)
  }
  const refreshMaster = useCallback(async () => {
    if (!condition.initFlag) {
      setIndex(statusIndex(condition.status));
      conditionActions.setStation(props.user.userClass !== constClass.CLASS.ADMIN ? ('0000' + props.user.shopId).slice(-4) : '');
      conditionActions.init();
      return;
    }
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    // var params =  {
    //   "operator": "and",
    //   "where": [
    //     { "site_id": props.siteId },
    //     { "operator":"gte","book_date": new Date(searchData.book_start) },
    //     { "operator":"lte","book_date": new Date(searchData.book_end) },
    //   ]
    // };
    const params = {
      site_id: props.siteId,
      book_start: condition.book_start,
      book_end: condition.book_end,
    }
    const calendarPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const stationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_station/listall`, null, { headers: { Authorization: `Bearer ${jwt}` } });
    const calendar = (await calendarPromise).data;
    const station = (await stationPromise).data;
    if (calendar.length > 0 && !condition.bookDate && calendar.findIndex(x => moment(x.book_date).format('YYYYMMDD') === moment().format('YYYYMMDD')) >= 0) {
      conditionActions.setBookDate(moment(calendar.find(x => moment(x.book_date).format('YYYYMMDD') === moment().format('YYYYMMDD')).book_date).format('YYYY/MM/DD'));
    }
    setCalendarList(calendar);
    conditionActions.setStationList(station);
  }, [props.siteId, props.user.shopId, props.user.userClass, condition.initFlag, condition.status, condition.book_start, condition.book_end, condition.bookDate, conditionActions]);

  useEffect(() => {
    refreshMaster();
  }, [refreshMaster]);

  const handleStation = (e) => {
    const target = e.target;
    // var tmp = { ...searchData, station: target.value };
    // console.log(tmp)
    conditionActions.setStation(target.value);
  }

  const changeBookDate = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    conditionActions.setBookDate(value);
  }

  const handleResetButtonClick = () => {
    conditionActions.logout();
  }

  const renderHeader = () => {
    return (
      <div className="mx-0">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>利用日付</span>
          </div>
          <div className="col-2 text-center">
            <select className="custom-select w-100 text-center border-textbox text-primary"
              value={condition.bookDate}
              id="bookDate" name="bookDate" onChange={changeBookDate}>
              <option value="" >- 選択 -</option>
              {calendarList.map((item, idx) =>
                <option key={idx} value={moment(item.book_date).format('YYYY/MM/DD')}>{moment(item.book_date).format('YYYY/MM/DD')}({moment(item.book_date).format('dd')})</option>
              )}
            </select>
            {/*  <DatePicker className="custom-select w-100 text-center border-textbox text-primary"
              aria_labal="利用日付" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="book_date" name="book_date"
              popperPlacement='bottom'
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
              }}
              selected={book_date ? new Date(book_date) : null}
              onChange={date => setBookDate(date ? moment(date).format('YYYY/MM/DD') : null)}
              placeholderText="- 選択 -"
              disabledKeyboardNavigation
              includeDates={calendarList.reduce((list, item) => list.concat(new Date(item.book_date)), [])}
              highlightDates={calendarList.reduce((list, item) => list.concat(new Date(item.book_date)), [])}
              customInput={<button>{book_date ? book_date : "- 選択 -"}</button>}
            /> */}
          </div>
          <div className="col-1 text-center align-self-center">
            <span>駅</span>
          </div>
          <div className="col-3 text-center">
            <select className="custom-select w-100" aria-label="駅" value={condition.station} onChange={handleStation}>
              <option value="">選択してください</option>
              {condition.stationList.filter(x => x.target_flg === '1').map((item) =>
                <option key={item.station_cd} value={item.station_cd}>{item.station_name}</option>
              )}
            </select>
          </div>
          <div className="col-2"></div>
          <div className="col-2 text-center align-self-center">
            <button type="button"
              className={`btn btn-secondary mx-1`}
              onClick={() => handleResetButtonClick()}>
              リセット
            </button>
          </div>
          {/* <div className="col-2 text-center">
            <input className="custom-select w-100" type="text" value={searchData.train_no} />
          </div> */}
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      {(condition.stationList.length > 0) && renderHeader()}
      <Tabs onSelect={handleTabSelect}>
        <div className="d-flex">
          <TabList className="nav nav-pills mb-2 w-75">
            <Tab className="nav-item"><button className={`nav-link ${index === 0 ? 'active' : ''}`}>乗車/降車</button></Tab>
            <Tab className="nav-item"><button className={`nav-link ${index === 1 ? 'active' : ''}`} href="#">降車済(完了)</button></Tab>
            <Tab className="nav-item"><button className={`nav-link ${index === 2 ? 'active' : ''}`} href="#">キャンセル</button></Tab>
          </TabList>
          <div></div>{/* 凡例 */}
        </div>
        <TabPanel>
          <TrainBookingList {...props} />
        </TabPanel>
        <TabPanel>
          <TrainBookingList statusIdx={index} {...props} />
        </TabPanel>
        <TabPanel>
          <TrainBookingList statusIdx={index} {...props} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default TrainBookingListTab;
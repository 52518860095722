import { combineReducers, configureStore } from '@reduxjs/toolkit';

import User from '../Slices/User';
import TrainBookingCondition from '../Slices/TrainBookingCondition';

const rootReducer = combineReducers({
  User: User.reducer,
  TrainBookingCondition: TrainBookingCondition.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import Modal from 'react-modal';
import Common from '../Components/Common/common.js';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import { ToastProvider } from 'react-toast-notifications';
// import BackyardOrderFinTab from '../Components/BackyardOrder/BackyardOrderFinTab';
// import PlanCategoryMaster from '../Components/Master/PlanCategory';
// import ProductCategoryMaster from '../Components/Master/ProductCategory';
// import constClass from '../Constants/Constants';
// import BackyardOrderInput from '../Components/BackyardOrder/BackyardOrderInput';
// import PlanMaster from '../Components/Master/Plan'
// import ProductMaster from '../Components/Master/Product'
// import ProductDept from '../Components/Master/ProductDept'
// import NoticeInterval from '../Components/Master/NoticeInterval';
import UserMaster from '../Components/Master/User';
import UserEdit from '../Components/Master/UserEdit';
import TrainCalendarMaster from '../Components/BackyardTrain/TrainCalendar';
import TrainBookingListTab from '../Components/BackyardTrain/TrainBookingListTab';
import TrainBin from '../Components/BackyardTrain/TrainBin';
import TrainBinList from '../Components/BackyardTrain/TrainBinList';
import TrainBookingCsv from '../Components/BackyardTrain/TrainBookingCsv';
import TrainBinAllStop from '../Components/BackyardTrain/TrainBinAllStop';
import TrainDaiya from '../Components/BackyardTrain/TrainDaiya';
import TrainStation from '../Components/BackyardTrain/TrainStation';
import TrainBooking from '../Components/BackyardTrain/Booking';
import TrainBookingConditionSlice from '../Slices/TrainBookingCondition';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const initSettings = async (siteId) => {
    const reg_params = {
      "operator": "and",
      "where": [{ "site_id": siteId }]
    }
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${siteId}/`, reg_params)).data;
    setSettings(data);
    const temp = {
      link: {
        rel: {
          icon: `/${siteId}/favicon.ico`,
          "apple-touch-icon": `/${siteId}/logo192.png`,
          manifest: `/${siteId}/manifest.json`,
        },
      },
      title: data.TITLE_NAME ? data.TITLE_NAME : '',
      description: data.TITLE_NAME ? data.TITLE_NAME : '',
    };
    setMeta(temp);
  };
  const [settings, setSettings] = useState(null);
  const [meta, setMeta] = useState({});
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);
  const conditionActions = useActions(TrainBookingConditionSlice.actions);
  const condition = useSelector(state => state.TrainBookingCondition);

  useEffect(() => {
    initSettings(props.match.params.siteId);
  }, [props.match.params.siteId]);

  const _userProps = { user, siteId: parseInt(props.match.params.siteId), userActions, settings, conditionActions, condition, ...props };
  return (
    <div className='backyard-top'>
      <DocumentMeta {...meta} />
      <Modal isOpen={Common.checkContractPeriod(settings) === false}>
        <div className="text-center section-white m-0 py-2">
          <h4>サービス契約期間外です</h4>
        </div>
      </Modal>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider placement="bottom-center">
            <Route render={() =>
              <UserOnly {..._userProps} >
                <Route exact path={`${props.match.path}book/input/`} >
                  <TrainBooking {..._userProps} refund_type={false} />
                </Route>
                <Route exact path={`${props.match.path}book/input/:book_id`} >
                  <TrainBooking {..._userProps} refund_type={false} />
                </Route>
                <Route exact path={`${props.match.path}book/refund/:book_id`} >
                  <TrainBooking {..._userProps} refund_type={true} />
                </Route>
                <Route exact path={`${props.match.path}book/input/:book_id/:book_no`} >
                  <TrainBooking {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}`} >
                  <TrainBookingListTab {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}trainbin/:bookDate/:trainNo`} >
                  <TrainBin {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}traindaiya/`} >
                  <TrainDaiya {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}calendar`} >
                  <TrainCalendarMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}trainstation`} >
                  <TrainStation {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}bookinglist`} >
                  <TrainBookingListTab {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}binlist`} >
                  <TrainBinList {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}bookingcsv`} >
                  <TrainBookingCsv {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}allstop`} >
                  <TrainBinAllStop {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user`} >
                  <UserMaster {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user/edit`} >
                  <UserEdit {..._userProps} />
                </Route>
                <Route path={`${props.match.path}user/edit/:id`} >
                  <UserEdit {..._userProps} />
                </Route>
              </UserOnly>
            } />
            <Route render={() =>
              <GuestOnly {..._userProps} >
                <Route path={`${props.match.path}login`} render={() =>
                  <Login {..._userProps} />
                } />
              </GuestOnly>
            } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

export default BackApp;
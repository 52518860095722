import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
// import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Reactcalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CSVLink } from "react-csv";
import { CSVReader } from 'react-papaparse'
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import CommonTrain from '../Common/common_train';
import Loading from '../Loading/Loading';

const uploadButtonRef = React.createRef();

const TrainCalendar = (props) => {
  const Today = new Date();
  const { addToast } = useToasts();
  const [disabled, setDisabled] = useState(false);
  const [selectDate, setSelectDate] = useState(Today);
  const [bookCount, setBookCount] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [calendarBinList, setCalendarBinList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const book_start = moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD')
  const [uploadFile, setUploadFile] = useState({ minDate: null, maxDate: null, list: [] });

  const refreshMaster = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const params = {
      site_id: props.siteId,
      book_start: book_start,
    }
    const calendarPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const traincarPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/train_car/${props.siteId}`);
    const holidayPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/holiday/search/`, params);
    const traincar = (await traincarPromise).data;
    const holiday = (await holidayPromise).data;
    const calendar = (await calendarPromise).data;
    const count = traincar.reduce((sum, item) => sum += parseInt(item.book_count), 0);
    //console.log(traincar, count)
    setBookCount(count)
    setCalendarList(calendar);
    setHolidayList(holiday);
  }, [props.siteId, book_start]);
  const refresCsvdata = useCallback(async () => {
    setDisabled(true);
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    var head = [];
    var list = [];
    var calendar = []

    const params = {
      site_id: props.siteId,
      book_start: moment(selectDate).startOf('month').format('YYYY-MM-DD'),
      book_end: moment(selectDate).endOf('month').format('YYYY-MM-DD'),
    }
    const trainBinPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/search/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const trainbin = (await trainBinPromise).data;
    setCalendarBinList(trainbin);
    const params_daiya = {
      site_id: props.siteId,
      book_start: moment(selectDate).startOf('month').format('YYYYMMDD'),
    }
    const traindaiyaPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_daiya_time/searchbin/`, params_daiya, { headers: { Authorization: `Bearer ${jwt}` } });
    const traindaiya = (await traindaiyaPromise).data;

    for (let index = moment(selectDate).startOf('month'); index <= moment(selectDate).endOf('month'); index.add(1, "day")) {
      calendar.push(index.format('YYYY-MM-DD'));
    }
    var train_no_list = traindaiya.reduce((list, item) => { if (list.findIndex(x => x.train_no === item.train_no && x.kaisei_date === item.kaisei_date && x.daiya_sbt === item.daiya_sbt) < 0) { list.push(item); } return list; }, []);
    head.push({ label: "列車番号", key: "train_no" });
    head.push({ label: "運行開始時刻", key: "start_ride_time" });
    head.push({ label: "改正日", key: "kaisei_date" });
    head.push({ label: "ダイヤ種別", key: "daiya_sbt" });
    head.push({ label: "上下区分", key: "jyoge_type" });
    calendar.forEach(item => head.push({ label: moment(item).format('YYYY/MM/DD'), key: `book_date_${moment(item).format('YYYYMMDD')}` }))
    train_no_list.sort((a, b) => a.kaisei_date !== b.kaisei_date ? a.kaisei_date - b.kaisei_date : a.daiya_sbt !== b.daiya_sbt ? Number(a.daiya_sbt) - Number(b.daiya_sbt) : a.jyoge_type !== b.jyoge_type ? Number(a.jyoge_type) - Number(b.jyoge_type) : Number(a.start_ride_time) - Number(b.start_ride_time)).forEach(item => {
      var data = {
        train_no: item.train_no,
        start_ride_time: item.start_ride_time,
        kaisei_date: item.kaisei_date,
        daiya_sbt: item.daiya_sbt,
        jyoge_type: item.jyoge_type,
      };
      calendar.forEach(book_date => {
        var data2 = trainbin.find(x => x.book_date === book_date && x.train_no === item.train_no && x.kaisei_date === item.kaisei_date && x.daiya_sbt === item.daiya_sbt);
        if (data2) data = { ...data, [`book_date_${moment(book_date).format('YYYYMMDD')}`]: data2.book_count }
      });
      list.push(data);
    });
    setCsvData({ header: head, list: list });
    setDisabled(false);
  }, [props.siteId, selectDate]);
  const getTileContent = (a) => {
    const cal = calendarList.find(x => moment(x.book_date).format('YYYYMMDD') === moment(a.date).format('YYYYMMDD'));
    return (
      <p className={`${cal ? "bg-yellow" : ""}`}>
        {cal ? "〇" : "-"}
      </p>
    );
  }
  // state の日付と同じ表記に変換
  const getFormatDate = (date) => {
    return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`;
  }
  const getTileClass = ({ date, view }) => {
    // 月表示のときのみ
    if (view !== 'month') {
      return '';
    }
    const day = getFormatDate(date);
    return (holidayList.findIndex(x => getFormatDate(new Date(x.holiday_date)) === day) >= 0 ? 'holiday' : '');
  }
  const changeDate = (date) => {
    setSelectDate(date)
  }
  const changeStartDate = (date) => {
    setSelectDate(date.activeStartDate);
  }
  const handleOnDrop = (datalist) => {
    setDisabled(true);
    var list = [];
    var minDate = null;
    var maxDate = null;
    for (let index = 1; index < datalist.length; index++) {
      var d = {
        site_id: props.siteId,
        train_no: datalist[index].data[0],
        start_ride_time: datalist[index].data[1],
        kaisei_date: datalist[index].data[2],
        daiya_sbt: datalist[index].data[3],
        jyoge_type: datalist[index].data[4],
        book_date: null,
        book_count: 0,
        book_status: constClass.FLAG.ON,
        book_stop_message: null,
      }
      for (let index2 = 5; index2 < datalist[0].data.length; index2++) {
        if (moment(datalist[0].data[index2]).isValid()) {
          if (minDate === null || moment(minDate) > moment(datalist[0].data[index2])) minDate = datalist[0].data[index2];
          if (maxDate === null || moment(maxDate) < moment(datalist[0].data[index2])) maxDate = datalist[0].data[index2];
          if (datalist[index].data[index2] !== "" && parseInt(datalist[index].data[index2]) > 0) {
            d = { ...d, book_date: datalist[0].data[index2], book_count: parseInt(datalist[index].data[index2]) > 0 ? bookCount : 0 }
            list.push(d);
          }
        }
      }
    }
    if (minDate === null || maxDate === null) {
      alert("アップロードされたファイルが正常に読み込めませんでした。");
      if (uploadButtonRef.current) {
        uploadButtonRef.current.removeFile();
      }
    } else {
      setUploadFile({ minDate, maxDate, list });
    }

    setDisabled(false);
  };
  const handleOnRemoveFile = () => {
    setUploadFile({ minDate: null, maxDate: null, list: [] });
  }
  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };
  const submit = async () => {
    setDisabled(true);
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    try {
      if (!window.confirm('登録しますか？')) {
        setDisabled(false);
        return;
      }
      var res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/`, { site_id: props.siteId, minDate: uploadFile.minDate, maxDate: uploadFile.maxDate, binlist: uploadFile.list }, { headers: { Authorization: `Bearer ${jwt}` } });
      //console.log(res)
      if (res.data && res.data.result) {
        addToast(`登録しました`, { appearance: 'success', autoDismiss: true });
        setUploadFile({ minDate: null, maxDate: null, list: [] });
        refreshMaster();
      } else {
        alert(`登録に失敗しました\n${res.data && res.data.message ? res.data.message : ""}`);
      }
      setUploadFile({ minDate: null, maxDate: null, list: [] });
      refreshMaster();
      if (uploadButtonRef.current) {
        uploadButtonRef.current.removeFile();
      }
    } catch (error) {
      console.log(util.inspect(error))
      const err_message = error.response && error.response.data && error.response.data.message ? error.response.data.message : "";
      alert(`登録に失敗しました\n${err_message}`);
      if (uploadButtonRef.current) {
        uploadButtonRef.current.removeFile();
      }
    }
    setDisabled(false);
  }

  useEffect(() => {
    refreshMaster();
    refresCsvdata();
  }, [refreshMaster, refresCsvdata]);


  return (
    <div className="container">
      <div className='row'>
        <div className='col-4'>
          <div className='row'>
            <div className='col pb-3'>
              <Reactcalendar locale="ja-JP" calendarType="US"
                onChange={changeDate}
                onActiveStartDateChange={changeStartDate}
                minDate={new Date(book_start)}
                value={selectDate}
                tileContent={getTileContent}
                tileClassName={getTileClass}
                className="w-100"
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              {csvData &&
                <CSVLink className="w-100 btn btn-active mb-1 px-4 py-3" data={csvData.list} headers={csvData.header}
                  enclosingCharacter={``}
                  filename={`サイクルトレイン予約${moment(selectDate).format('YYYYMM')}.csv`}>
                  {moment(selectDate).format('YYYY年MM月')}予約設定CSVダウンロード
                </CSVLink>
              }
            </div>
          </div>
        </div>
        <div className='col-8'>
          <div className='row'>
            <div className='col upload-csv-file-area'>
              <CSVReader
                ref={uploadButtonRef}
                disabled={disabled}
                onDrop={handleOnDrop}
                onError={handleOnError}
                addRemoveButton
                onRemoveFile={handleOnRemoveFile}
              >
                <span>予約設定CSVファイルを選択してください</span>
              </CSVReader>
            </div>
          </div>
          <div className='row'>
            <div className='col text-center p-3'>
              {uploadFile.minDate !== null &&
                <React.Fragment>
                  <h4>更新対象期間：{moment(uploadFile.minDate).format('YYYY/MM/DD')}～{moment(uploadFile.maxDate).format('YYYY/MM/DD')}</h4>
                  <div><button
                    disabled={disabled}
                    className='btn btn-active w-50'
                    onClick={submit}
                  >登録</button>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              {selectDate &&
                <div className='row py-3'>
                  <div className='col'><h5>{moment(selectDate).format('YYYY/MM/DD')}</h5></div>
                </div>
              }
              {(selectDate && calendarBinList.findIndex(x => moment(x.book_date).format('YYYYMMDD') === moment(selectDate).format('YYYYMMDD')) < 0) &&
                <div className='row'>
                  <div className='col'>
                    予約受付数は登録されていません
                  </div>
                </div>
              }
              {(selectDate && calendarBinList.findIndex(x => moment(x.book_date).format('YYYYMMDD') === moment(selectDate).format('YYYYMMDD')) >= 0) &&
                <div className='row'>
                  {[constClass.TRAIN_JYOGE_TYPE.NOBORI, constClass.TRAIN_JYOGE_TYPE.KUDARI].map(joge => (
                    <div className='col-6 pb-3' key={joge}>
                      <table className='w-100 table-striped text-center'>
                        <thead className='thead-light'>
                          <tr>
                            <th>列車番号</th>
                            <th>開始時刻</th>
                            <th>予約受付台数</th>
                            <th>ダイヤ<br />種別</th>
                            <th>ステータス</th>
                          </tr>
                        </thead>
                        <tbody>
                          {calendarBinList.filter(x => moment(x.book_date).format('YYYYMMDD') === moment(selectDate).format('YYYYMMDD') && x.jyoge_type === joge).map((item, idx) => (
                            <tr key={idx}>
                              <td>
                                <Link to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}trainbin/${moment(item.book_date).format('YYYYMMDD')}/${item.train_no}`}>{item.train_no}</Link>
                              </td>
                              <td>
                                {CommonTrain.strTotime(item.start_ride_time)}
                              </td>
                              <td>
                                {item.book_count}
                              </td>
                              <td>
                                {item.daiya_sbt}
                              </td>
                              <td>
                                {item.book_status === constClass.FLAG.ON ? "受付中" : item.book_stop_message}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              }</div>
          </div>
        </div>
      </div>
      {disabled && <Loading />}
    </div>
  );
}

export default TrainCalendar
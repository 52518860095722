import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import CommonTrain from '../Common/common_train';

const TrainBookingList = (props) => {
  const { condition } = props;
  const [allBookData, setAllBookData] = useState(null);
  const [bookData, setBookData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [sortOrder, setSortOrder] = useState([]); // ソート順指定

  const { addToast } = useToasts();
  const componentRef = useRef();
  const refreshBook = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    var reg_params = {};
    reg_params = {
      "operator": "and",
      "where": [
        { "site_id": props.siteId },
        { "book_date": new Date(condition.bookDate) }
      ]
    }
    // 店舗
    // if (props.user.userClass !== constClass.CLASS.ADMIN) {
    //   //店舗ユーザは自分の店舗のみ
    //   reg_params.where.push({ "operator": "or", "where": [{ "ride_station_cd": props.user.shopId }, { "getoff_station_cd": props.user.shopId }] });
    // }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/search/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setAllBookData(data);
    setBookData(data.filter(item => (condition.status === '' || condition.status.includes(item.status)) && (condition.station === '' || [item.ride_station_cd, item.getoff_station_cd].includes(condition.station))));

  }, [props.siteId, condition.status, condition.station, condition.bookDate]);

  const handleStatusButtonClick = async (book_id, book_no, status, line_id) => {

    var book = bookData.find(o => (o.book_id === book_id && o.book_no === book_no));
    var book2 = allBookData.find(o => (o.book_id === book_id && o.book_no !== book_no));
    if (moment(book.book_date).format('YYYYMMDD') !== moment().format('YYYYMMDD') && status === constClass.STATUS.RDE) {
      //当日以外は乗車不可
      alert(`${moment(book.book_date).format('YYYY/MM/DD')}のデータです。乗車処理できません。`);
      return;
    }
    if (([constClass.STATUS.RDE, constClass.STATUS.FIN].includes(status)) && book.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && book2 && !([constClass.STATUS.FIN].includes(book2.status))) {
      alert(`予約No[${book.view_book_no}]は復路予約で往路がまだ完了（降車）していないため、乗車処理できません`);
      return;
    }
    if (status === constClass.STATUS.FIN && book.status !== constClass.STATUS.RDE) {
      alert(`予約No[${book.view_book_no}]は乗車していないため、終了(降車)にできません`);
      return
    }
    //console.log(status, book, book2)
    var message = "";
    if (book.status === status) {
      /*  // 同じボタンを押したときはひとつ前のステータスに戻す
       status = (status === constClass.STATUS.FIN) ? constClass.STATUS.RDE : constClass.STATUS.REG;
       message = `${constClass.STATUS_NAME[book.status]}を${constClass.STATUS_NAME[status]}に変更します。`; */
      return;//ステータス戻しは対応しない
    }

    if (status === constClass.STATUS.FIN) {
      message = `予約No[${book.view_book_no}]を終了(降車)にします。よろしいですか？`;
    }
    if (status === constClass.STATUS.RDE) {
      message = `予約No[${book.view_book_no}]を乗車にします。よろしいですか？`;
    }
    if (status === constClass.STATUS.CCL && book2 && constClass.STATUS_PRE.includes(book2.status)) {

    }
    if (status === constClass.STATUS.CCL) {
      message = `予約No[${book.view_book_no}]をキャンセルします。よろしいですか？`;
      if (book.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && book2 && [constClass.STATUS.RDE, constClass.STATUS.FIN].includes(book2.status)) {
        message += `\n往復予約の復路で、往路(No${book2.view_book_no})が${constClass.STATUS_NAME[book2.status]}のため、\n窓口返金が必要です。`
        status = constClass.STATUS.CFIN;
      } else
        if (book2 && constClass.STATUS_PRE.includes(book2.status)) {
          message += `\n往復予約のため、${constClass.TRAIN_ROUND_TYPE[book2.round_type]}(No${book2.view_book_no})もあわせてキャンセルします。`
          book_no = null;
        } else {
          book_no = null;
        }
    }

    if (!window.confirm(message)) {
      return;
    }
    const params = {
      site_id: props.match.params.siteId,
      status,
      line_id: line_id,
      upd_name: props.user.userId
    };
    try {
      setLockData(true);
      //トークンを取得
      const jwt = localStorage.getItem('jwt');

      if (book_no !== null) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/train_booking/backyard_status/${book_id}/${book_no}`, params, { headers: { Authorization: `Bearer ${jwt}` } });
      } else {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/train_booking/backyard_status/${book_id}`, params, { headers: { Authorization: `Bearer ${jwt}` } });
      }

      addToast('ステータスを更新しました。', { appearance: 'success', autoDismiss: true });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      await refreshBook();
      setLockData(false);
    }
  }
  const renderCancelButton = (data) => {
    const datalist = allBookData.filter(x => x.book_id === data.book_id);
    const chk = datalist.reduce((chk, item) => (chk && constClass.STATUS_PRE.includes(item.status)), true);
    const statusName = (chk || !constClass.STATUS_NFIN.includes(data.status)) ? "キャンセル" : "払い戻し";
    const status = (chk) ? constClass.STATUS.CCL : constClass.STATUS.CFIN;
    const additionalStatus = [constClass.STATUS.CCL, constClass.STATUS.CFIN];
    return (
      (chk || !constClass.STATUS_NFIN.includes(data.status)) ? <button type="button"
        disabled={lockData || !constClass.STATUS_PRE.includes(data.status)}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status] && constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-active' : 'btn-secondary')} mx-1`
        }
        onClick={() => handleStatusButtonClick(data.book_id, data.book_no, status, data.line_id)}>
        {statusName}
      </button > :
        <Link to={`${generatePath(`${props.match.path}book/refund/:book_id/`, { siteId: props.match.params.siteId, book_id: data.book_id })}`}>
          <button type="button"
            disabled={lockData}
            className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status] && constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-active' : 'btn-secondary')} mx-1`}
          >
            {statusName}
          </button>
        </Link>
    )
  }
  const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    return (
      <button type="button"
        disabled={lockData || constClass.STATUS_CCL.includes(data.status) || (status === constClass.STATUS.CCL && !(data.status === constClass.STATUS.CPRE || constClass.STATUS_PRE.includes(data.status))) || (status !== constClass.STATUS.CCL && !constClass.STATUS_RDEOK.includes(data.status))}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status] && constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-active' : 'btn-secondary')} mx-1`}
        onClick={() => handleStatusButtonClick(data.book_id, data.book_no, status, data.line_id)}>
        {statusName}
      </button>
    )
  }

  const handleHeaderClick = (column) => {
    var _sortOrder = sortOrder;
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    setSortOrder(_sortOrder);
    var data = bookData.slice();
    data.sort((a, b) => sortData(a, b));
    setBookData(data);
  }

  const sortData = useCallback((a, b) => {
    if (sortOrder.length <= 0) { // 初期値は予約日・予約時間・予約ID・予約No 昇順
      return (a.book_date !== b.book_date) ? Number(a.book_date) - Number(b.book_date) : (a.ride_time !== b.ride_time) ? Number(a.ride_time) - Number(b.ride_time) : (a.book_id !== b.book_id) ? Number(a.book_id) - Number(b.book_id) : Number(a.book_no) - Number(b.book_no);
    }
    for (var i = 0; i < sortOrder.length; i++) {
      var item = sortOrder[i];
      if (item.column === 'count') {
        var count_a = a.count_adult1 + a.count_child1 + a.count_adult2 + a.count_child2;
        var count_b = b.count_adult1 + b.count_child1 + b.count_adult2 + b.count_child2;
        if (item.order === 'ASC') {
          if (count_a < count_b) return -1;
          if (count_a > count_b) return 1;
        } else {
          if (count_b < count_a) return -1;
          if (count_b > count_a) return 1;
        }
      } else if (item.column === 'ride_time' || item.column === 'getoff_time') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else {
        //console.log(a[item.column]);
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }, [sortOrder]);

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)}>
      {sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await refreshBook();
      intervalId = setInterval(() => {
        refreshBook();
      }, 60000);
    }
    fetchData();
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshBook]);


  const renderList = () => {
    return (
      <div ref={componentRef} className="print-list">
        <div className="row mb-0 p-0 ">
          <div className="col pl-0 text-left align-self-end">
            {(condition.station !== '' && condition.stationList.length > 0) && <div className="row"><div className="col-12"><h4>{condition.stationList.find(s => s.station_cd === condition.station).station_name}</h4></div></div>}
          </div>
        </div>
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bbooked table-striped" height="1">
              <thead>
                <tr>
                  <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('book_id')}>
                    管理<br />番号
                  </th>
                  <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('book_no')}>
                    予約<br />番号
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('train_no')}>
                    列車番号
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('ride_time')}>
                    乗車<br />時刻
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('ride_station_cd')}>
                    乗車駅
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('getoff_time')}>
                    降車<br />時刻
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('getoff_station_cd')}>
                    降車駅
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('count')}>
                    台数・人数
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_kana')}>
                    氏名
                  </th>
                  <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('status')}>
                    ステータス
                  </th>
                  <th className="text-center align-middle print-none">
                    処理
                  </th>
                </tr>
                <tr className="print-none">
                  {renderSortTh('book_id')}
                  {renderSortTh('book_no')}
                  {renderSortTh('train_no')}
                  {renderSortTh('ride_time')}
                  {renderSortTh('ride_station_cd')}
                  {renderSortTh('getoff_time')}
                  {renderSortTh('getoff_station_cd')}
                  {renderSortTh('count')}
                  {renderSortTh('customer_kana')}
                  {renderSortTh('status')}
                  <th className="text-center align-middle print-none">
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookData.length <= 0 && <tr><td className="text-center align-middle p-3" colSpan={10}>条件に一致する予約がありません</td></tr>}
                {bookData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                  <tr key={idx}>
                    {/* 管理番号 */}
                    <td className="text-center align-middle p-1">
                      <Link to={`${generatePath(`${props.match.path}book/input/:book_id/`, { siteId: props.match.params.siteId, book_id: data.book_id })}`}>{data.book_id}</Link>
                    </td>
                    {/* 予約番号 */}
                    <td className="text-center align-middle p-1">
                      {data.view_book_no}
                    </td>
                    {/* 列車番号 */}
                    <td className="text-center align-middle p-1">
                      <Link to={`${generatePath(`${props.match.path}trainbin/:book_date/:train_no`, { siteId: props.match.params.siteId, book_date: moment(data.book_date).format('YYYYMMDD'), train_no: data.train_no })}`}>{data.train_no}</Link>
                    </td>
                    {/* 乗車時刻 */}
                    <td className="text-center align-middle p-1">
                      {CommonTrain.strTotime(data.ride_time)}<br />
                    </td>
                    {/* 乗車駅 */}
                    <td className="text-center align-middle p-1">
                      {data.ride_station_name}
                    </td>
                    {/* 降車時刻 */}
                    <td className="text-center align-middle p-1">
                      {CommonTrain.strTotime(data.getoff_time)}<br />
                    </td>
                    {/* 乗車駅 */}
                    <td className="text-center align-middle p-1">
                      {data.getoff_station_name}
                    </td>
                    {/* 台数・人数 */}
                    <td className="text-center align-middle p-1">
                      {data.book_type === constClass.TRAIN_BOOK_TYPE.BRINGIN && <div className='row'><div className='col'>持込のみ</div><div className='col-3'>{data.count_bringin}</div></div>}
                      {data.book_type !== constClass.TRAIN_BOOK_TYPE.BRINGIN &&
                        <div>
                          {data.count_adult1 > 0 && <div className='row'><div className='col'>大人</div><div className='col-3'>{data.count_adult1}</div></div>}
                          {data.count_child1 > 0 && <div className='row'><div className='col'>小児</div><div className='col-3'>{data.count_child1}</div></div>}
                          {data.count_adult2 > 0 && <div className='row'><div className='col'>大人(割)</div><div className='col-3'>{data.count_adult2}</div></div>}
                          {data.count_child2 > 0 && <div className='row'><div className='col'>小児(割)</div><div className='col-3'>{data.count_child2}</div></div>}
                        </div>}
                    </td>
                    {/* 氏名 */}
                    <td className="text-center align-middle p-1">
                      {data.customer_kana}
                    </td>
                    {/* ステータス */}
                    <td className="text-center align-middle p-1">
                      {constClass.STATUS_NAME[data.status]}
                    </td>
                    {/* 処理 */}
                    <td className="text-left align-middle text-nowrap p-1 print-none">
                      {renderButton(data, constClass.STATUS.RDE, constClass.STATUS_NAME.RDE)}
                      {renderButton(data, constClass.STATUS.FIN, constClass.STATUS_NAME.FIN)}
                      {renderCancelButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(bookData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(props.user)}</div></div>
      {bookData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {bookData !== null && renderList()}
    </div>
  )
}

export default TrainBookingList;
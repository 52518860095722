import React, { useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from "react-csv";
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';

const TrainBookingCsv = (props) => {
  const [lockData, setLockData] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState(null);
  const { addToast } = useToasts();
  const csvRef = useRef();

  const BookingSearch = async () => {
    setLockData(true);
    const jwt = localStorage.getItem('jwt');
    const reg_params = {
      "operator": "and",
      "where": [
        {
          "operator": "gte",
          "attr": "book_date",
          "val": moment(startDate).format('YYYY-MM-DD')
        },
        {
          "operator": "lte",
          "attr": "book_date",
          "val": moment(endDate).format('YYYY-MM-DD')
        },
        {
          "operator": "eq",
          "attr": "site_id",
          "val": props.siteId
        },
      ]
    };

    // DB取得
    const booking_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    booking_data.sort((a, b) => a.book_date > b.book_date ? 1 : (a.book_date === b.book_date && a.book_id > b.book_id) ? 1 : (a.book_date === b.book_date && a.book_id === b.book_id && a.book_no > b.book_no) ? 1 : -1);
    //console.log(booking_data);
    setBookingData(booking_data);
    CreateCsvData(booking_data);
    setLockData(false);
  }

  // ChangeCell
  const dateChange = (event) => {
    // 開始日
    if (event.target.name === "book_date_start") {
      setStartDate(event.target.value);

      // 終了日
    } else if (event.target.name === "book_date_end") {
      setEndDate(event.target.value);
    }
  }

  // CSVset（予約データ）
  const CreateCsvData = (data) => {
    var list = [];
    var head = [];
    head.push({ label: "乗車日", key: "book_date" });
    head.push({ label: "管理ID", key: "book_id" });
    head.push({ label: "No", key: "book_no" });
    head.push({ label: "利用者ID", key: "customer_id" });
    head.push({ label: "列車番号", key: "train_no" });
    head.push({ label: "発駅CD", key: "ride_station_cd" });
    head.push({ label: "発駅名", key: "ride_station_name" });
    head.push({ label: "着駅CD", key: "getoff_station_cd" });
    head.push({ label: "着駅名", key: "getoff_station_name" });
    head.push({ label: "大人人数", key: "count_adult1" });
    head.push({ label: "小児人数", key: "count_child1" });
    head.push({ label: "大人(障)人数", key: "count_adult2" });
    head.push({ label: "小児(障)人数", key: "count_child2" });
    head.push({ label: "台数", key: "count_bringin" });
    head.push({ label: "普通券運賃計", key: "fare" });
    head.push({ label: "持ち込み料計", key: "fare_bringin" });
    head.push({ label: "返金", key: "refund" });
    head.push({ label: "決済手段", key: "payment_type" });
    head.push({ label: "決済手段", key: "payment_type_name" });
    head.push({ label: "状態", key: "status" });
    head.push({ label: "お名前(カナ)", key: "customer_kana" });
    head.push({ label: "居住地", key: "customer_address" });
    head.push({ label: "性別", key: "customer_gender" });
    head.push({ label: "年代", key: "customer_age" });
    head.push({ label: "予約日時", key: "ins_date" });
    head.push({ label: "乗車日時", key: "ride_datetime" });
    head.push({ label: "降車日時", key: "getoff_datetime" });
    head.push({ label: "キャンセル日時", key: "cancel_datetime" });
    data.forEach(p => {
      const p2 = data.filter(x => x.book_id === p.book_id && constClass.STATUS.CFIN === x.status).sort((a, b) => b.book_no - a.book_no);
      const refund = constClass.STATUS.CCL === p.status ? p.total : p2.length >= 1 && p2[0].book_no === p.book_no ? Number(p.refund_total) : "";
      var booking = {
        book_date: moment(p.book_date).format("YYYY/MM/DD"),
        book_id: p.book_id,
        book_no: p.book_no,
        customer_id: p.customer_id,
        train_no: p.train_no,
        ride_station_cd: p.ride_station_cd,
        ride_station_name: p.ride_station_name,
        getoff_station_cd: p.getoff_station_cd,
        getoff_station_name: p.getoff_station_name,
        count_adult1: p.count_adult1,
        count_child1: p.count_child1,
        count_adult2: p.count_adult2,
        count_child2: p.count_child2,
        count_bringin: p.count_bringin,
        fare: p.total - (p.fare_bringin * p.count_bringin),
        fare_bringin: p.fare_bringin * p.count_bringin,
        refund: refund,
        payment_type: p.payment_type ? constClass.PAYMENT_TYPE_CSV[p.payment_type] : '',
        payment_type_name: p.payment_type ? constClass.PAYMENT_TYPE_NAME_CSV[p.payment_type] : '',
        status: constClass.STATUS_NAME_CSV[p.status],
        customer_kana: p.customer_kana,
        customer_address: p.customer_address,
        customer_gender: constClass.CUSTOMER_GENDER_NAME[p.customer_gender],
        customer_age: constClass.CUSTOMER_AGE_TYPE_NAME[p.customer_age_type],
        ins_date: moment(p.ins_date).format("YYYY/MM/DD HH:mm:ss"),
        ride_datetime: p.ride_datetime ? moment(p.ride_datetime).format("YYYY/MM/DD HH:mm:ss") : '',
        getoff_datetime: p.getoff_datetime ? moment(p.getoff_datetime).format("YYYY/MM/DD HH:mm:ss") : '',
        cancel_datetime: p.cancel_datetime ? moment(p.cancel_datetime).format("YYYY/MM/DD HH:mm:ss") : '',
      };
      list.push(booking);
    });
    setCsvData({ header: head, list: list });
  }

  const handleExportClick = async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.siteId,
      command: 'booking_csv_export',
      book_id: csvData.list.map(b => b.book_id)
    }
    try {
      setLockData(true);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/log/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      
      csvRef.current.link.click();
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setLockData(false);
    }
  }


  // 検索ボタン
  const searchButton = () => {
    return (
      <button type="button"
        disabled={lockData || !startDate || !endDate}
        className={`btn btn-primary mx-5`}
        onClick={() => BookingSearch()}>
        検索
      </button>
    )
  }

  return (
    <div className="container">

      {/* 開始日・終了日入力フォーム */}
      <div className="row mb-3 p-0">
        <div className="col-2">
          <div className="d-flex align-items-center justify-content-center pt-2">
            乗車日：
          </div>
        </div>
        <div className="col-3">
          <div className="col-sm-12 csv-input">
            <input type="date" className="form-control" id={"book_date_start"} name={"book_date_start"} value={moment(startDate).format('YYYY-MM-DD')} onChange={dateChange}></input>
          </div>
        </div>
        <div className="col-1">
          <div className="d-flex align-items-center justify-content-center pt-2">
            ～
          </div>
        </div>
        <div className="col-3">
          <div className="col-sm-12">
            <input type="date" className="form-control" id={"book_date_end"} name={"book_date_end"} value={moment(endDate).format('YYYY-MM-DD')} onChange={dateChange}></input>
          </div>
        </div>
        {/* 検索ボタン */}
        <div className="col-3">
          <div className="col-sm-12">
            {searchButton()}
          </div>
        </div>
      </div>

      {/* 件数出力 */}
      {bookingData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-11 p-0 d-flex align-items-center justify-content-center ">
            <table className="table table-bordered table-striped w-50">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center">
                    検索件数
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    {bookingData ? bookingData.length : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="row btn-toolbar">
        <div className="btn-group col-11">
          <div className="offset-4 col-4">
            {csvData !== null && (
              <React.Fragment>
                <button
                  className="btn btn-primary print-none mb-1 px-0 w-100"
                  disabled={lockData}
                  onClick={() => handleExportClick()}>
                  予約データ出力
                </button>
                <CSVLink className="d-none" data={csvData.list} headers={csvData.header} filename={"予約データ.csv"} ref={csvRef} />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}

export default TrainBookingCsv
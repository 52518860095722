import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import util from 'util';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { CSVLink } from "react-csv";

const TrainBinList = (props) => {
  const [bookDate, setBookDate] = useState(null);
  const [jyogeType, setJyogeType] = useState('');
  const [binList, setBinList] = useState(null);
  const [binListData, setBinListData] = useState(null);
  const [calendarList, setCalendarList] = useState([]);
  const [stationList, setStationMaster] = useState([]);
  const [trainNoList, setTrainNoList] = useState([]);
  const [csvData, setCsvData] = useState(null);

  const refreshMaster = useCallback(async () => {
    //console.log("refreshMaster");
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    //前月から2ヶ月先まで
    const params = {
      site_id: props.siteId,
      book_start: moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD'),
      book_end: moment().add(2, 'month').endOf('month').format('YYYY/MM/DD'),
    }
    const calendarPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const stationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_station/listall`, null, { headers: { Authorization: `Bearer ${jwt}` } });
    const calendar = (await calendarPromise).data;
    const station = await (await stationPromise).data.filter(x => x.target_flg === constClass.FLAG.ON).sort((a, b) => a.view_no - b.view_no);
    setCalendarList(calendar);
    setStationMaster(station);
    if (calendar.length > 0 && bookDate === null) {
      const idx = calendar.findIndex(x => moment(x.book_date).format('YYYY/MM/DD') === moment().format('YYYY/MM/DD'));
      setBookDate(calendar[idx >= 0 ? idx : 0].book_date);
    }
  }, [props.siteId, bookDate]);
  const refreshData = useCallback(async () => {
    //console.log("refreshData");
    try {
      //トークンを取得
      const jwt = localStorage.getItem('jwt');
      var reg_params = {};
      reg_params = {
        "operator": "and",
        "where": [
          { "site_id": props.siteId },
          { "book_date": bookDate },
        ]
      }
      const binPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_detail/searchbin/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } });
      var data = await (await binPromise).data;
      setBinList(data);
    } catch (error) {
      console.log(util.inspect(error))
      setBinList(null);
    }
  }, [props.siteId, bookDate]);
  const createListData = (async () => {
    //console.log("createListData", jyogeType);
    var data = jyogeType !== '' ? await binList.filter(x => x.jyoge_type === jyogeType) : binList;
    var data2 = [];
    var train_no_list = await data.reduce((list, item) => { if (list.findIndex(x => x.train_no === item.train_no) < 0) { list.push({ train_no: item.train_no, jyoge_type: item.jyoge_type, des_station_cd: item.des_station_cd, start_ride_time: item.start_ride_time }); } return list; }, []);

    train_no_list.sort((a, b) => a.jyoge_type !== b.jyoge_type ? a.jyoge_type - b.jyoge_type : a.start_ride_time - b.start_ride_time).forEach(async (train) => {
      data2[train.train_no] = {}
      stationList.forEach(station => {
        data2[train.train_no] = { ...data2[train.train_no], [station.station_cd]: { count_ride: 0, count_getoff: 0, count_riding: 0 } }
        data.filter(x =>
          x.train_no === train.train_no
          && (x.station_cd1 === station.station_cd || x.station_cd2 === station.station_cd)
        ).forEach((item, idx) => {
          var count_ride = item.station_cd1 === station.station_cd ? parseInt(item.count_ride) || 0 : data2[train.train_no][station.station_cd].count_ride;
          var count_getoff = item.station_cd2 === station.station_cd ? parseInt(item.count_getoff) || 0 : data2[train.train_no][station.station_cd].count_getoff;
          var count_riding = ((item.jyoge_type === constClass.TRAIN_JYOGE_TYPE.NOBORI && item.station_cd2 === station.station_cd)
            || (item.jyoge_type === constClass.TRAIN_JYOGE_TYPE.KUDARI && item.station_cd1 === station.station_cd))
            ? parseInt(item.count_riding) || 0 : data2[train.train_no][station.station_cd].count_riding;
          if (station.station_cd === item.des_station_cd) {
            count_ride = "-";
          }
          if (item.getoff_time === "0") {
            count_getoff = "-";
          }
          data2[train.train_no] = {
            ...data2[train.train_no], [station.station_cd]: {
              ...item
              , count_ride: count_ride
              , count_getoff: count_getoff
              , count_riding: count_riding
            }
          };
        });
      });
    });
    setTrainNoList(train_no_list);
    const csvData = await createCsvdata(train_no_list, data2);
    setBinListData(data2);
    setCsvData(csvData);
  });

  const createCsvdata = async (trainnolist, data2) => {
    if (!trainnolist || trainnolist.length <= 0 || !data2) {
      return false
    };
    var data = [];
    var head = [];
    var sum = { book_date: "", jyoge_type: "", train_no: "駅乗車計", ride_count: 0 }
    head.push({ label: "乗車日", key: "book_date" });
    head.push({ label: "上下区分", key: "jyoge_type" });
    head.push({ label: "列車番号", key: "train_no" });
    stationList.forEach((s, idx) => {
      head.push({ label: `${s.station_name}_乗車`, key: `station_${s.station_cd}_1` });
      head.push({ label: `_降車`, key: `station_${s.station_cd}_2` });
      if (idx < (stationList.length - 1)) head.push({ label: `⇔`, key: `station_${s.station_cd}_0` });
      sum = { ...sum, [`station_${s.station_cd}_0`]: "", [`station_${s.station_cd}_1`]: 0, [`station_${s.station_cd}_2`]: 0 }
    });
    head.push({ label: "列車乗車数", key: "ride_count" })
    trainnolist.forEach(train => {
      var ride_count = 0;
      var csv = {
        book_date: moment(bookDate).format('YYYYMMDD'),
        jyoge_type: constClass.TRAIN_JYOGE_TYPE_NAME[train.jyoge_type],
        train_no: train.train_no,
      };
      stationList.forEach((s, idx) => {
        csv = { ...csv, [`station_${s.station_cd}_1`]: data2[train.train_no][s.station_cd].count_ride };
        csv = { ...csv, [`station_${s.station_cd}_2`]: data2[train.train_no][s.station_cd].count_getoff };
        if (idx < (stationList.length - 1)) csv = { ...csv, [`station_${s.station_cd}_0`]: data2[train.train_no][s.station_cd].count_riding };
        ride_count = ride_count + (isNaN(data2[train.train_no][s.station_cd].count_ride) ? 0 : parseInt(data2[train.train_no][s.station_cd].count_ride));
        sum = {
          ...sum
          , [`station_${s.station_cd}_1`]: sum[`station_${s.station_cd}_1`] + (isNaN(data2[train.train_no][s.station_cd].count_ride) ? 0 : parseInt(data2[train.train_no][s.station_cd].count_ride))
          , [`station_${s.station_cd}_2`]: sum[`station_${s.station_cd}_2`] + (isNaN(data2[train.train_no][s.station_cd].count_getoff) ? 0 : parseInt(data2[train.train_no][s.station_cd].count_getoff))
        }
      });
      csv = { ...csv, ride_count: ride_count };
      sum = { ...sum, ride_count: sum.ride_count + ride_count };
      data.push(csv);
    });
    data.push(sum);
    return ({ header: head, list: data });
  }
  useEffect(() => {
    async function fetchData() {
      await refreshMaster();
      await refreshData();
    }
    fetchData()
  }, [refreshMaster, refreshData]);

  const handleChangeBookDate = (event) => {
    const target = event.target;
    const value = target.value;
    setBinListData(null);
    setBookDate(value);
  }
  const handleChangeJyogeType = (e) => {
    const target = e.target;
    setBinListData(null);
    setJyogeType(target.value);
  }
  const renderHeader = () => {
    return (
      <div className="mx-0">
        <div className="row mb-3">
          <div className="col-1 text-center align-self-center">
            <span>利用日付</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <select className="custom-select w-100 text-center border-textbox text-primary"
              value={bookDate || ''}
              id="bookDate" name="bookDate" onChange={handleChangeBookDate}>
              <option value="" >- 選択 -</option>
              {calendarList.map((item, idx) =>
                <option key={idx} value={item.book_date}>{moment(item.book_date).format('YYYY/MM/DD')}({moment(item.book_date).format('dd')})</option>
              )}
            </select>
          </div>
          <div className="col-1 text-center align-self-center">
            <span>上下区分</span>
          </div>
          <div className="col-3 text-center align-self-center">
            <select className="custom-select w-100" aria-label="駅" id="jyoge_type" name="jyoge_type" value={jyogeType} onChange={handleChangeJyogeType}>
              <option value="">-- 選択 --</option>
              <option value={constClass.TRAIN_JYOGE_TYPE.NOBORI}>{constClass.TRAIN_JYOGE_TYPE_NAME.NOBORI}</option>
              <option value={constClass.TRAIN_JYOGE_TYPE.KUDARI}>{constClass.TRAIN_JYOGE_TYPE_NAME.KUDARI}</option>
            </select>
          </div>
          <div className="col-3 text-left align-self-center">
            <button className='btn btn-active'
              onClick={async (e) => { await createListData() }}
            >検索</button>
          </div>
          {csvData &&
            <div className="col p-0 text-right align-self-center">
              <CSVLink className="btn btn-active mb-1 px-4" data={csvData.list} headers={csvData.header} filename={`サイクルトレイン乗車状況確認表${moment(bookDate).format('YYYYMMDD')}.csv`}>
                CSVダウンロード
              </CSVLink>
            </div>
          }
        </div>
      </div>
    )
  }
  const renderList = () => {
    return (
      <div className="print-list">
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped" height="1">
              <thead>
                <tr>
                  <th className="text-center align-middle like-button" rowSpan={2}>
                    列車番号
                  </th>
                  <th className="text-center align-middle like-button" rowSpan={2}>
                    上下区分
                  </th>
                  {stationList.map((s, idx) => (
                    <React.Fragment key={idx}>
                      <th className="text-center align-middle like-button" colSpan={2}>
                        {s.station_name}
                      </th>
                      {idx < (stationList.length - 1) && <th className="text-center align-middle like-button">
                        ⇔
                      </th>}
                    </React.Fragment>
                  ))}
                </tr>
                <tr>
                  {stationList.map((s, idx) => (
                    <React.Fragment key={idx}>
                      <th className="text-center align-middle like-button">
                        乗
                      </th>
                      <th className="text-center align-middle like-button">
                        降
                      </th>
                      {idx < (stationList.length - 1) && <th className="text-center align-middle like-button">
                        数
                      </th>}
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {trainNoList.map((train, idx) => (
                  <tr key={idx}>
                    <td>
                      <Link to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}trainbin/${moment(bookDate).format('YYYYMMDD')}/${train.train_no}`}>{train.train_no}</Link>
                    </td>
                    <td>
                      {constClass.TRAIN_JYOGE_TYPE_NAME[train.jyoge_type]}
                    </td>
                    {stationList.map((s, idx2) => (
                      (binListData && binListData[train.train_no] && binListData[train.train_no][s.station_cd]) &&
                      <React.Fragment key={idx2}>
                        <td className={`text-center ${(binListData[train.train_no][s.station_cd].count_ride > 0) ? "text-white bg-dark font-weight-bold" : ""}`}>
                          <p className="m-0 p-1">{binListData[train.train_no][s.station_cd].count_ride}</p>
                        </td>
                        <td className={`text-center ${(binListData[train.train_no][s.station_cd].count_getoff > 0) ? "text-white bg-dark font-weight-bold" : ""}`}>
                          <p className="m-0 p-1">{binListData[train.train_no][s.station_cd].count_getoff}</p>
                        </td>
                        {idx2 < (stationList.length - 1) &&
                          <td className="text-center">
                            <p className="m-0 p-1">{binListData[train.train_no][s.station_cd].count_riding}</p>
                          </td>}
                      </React.Fragment>
                    ))}
                  </tr>
                )

                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      {(stationList.length > 0) && renderHeader()}
      {binListData !== null && renderList()}
    </div>
  )
}

export default TrainBinList;
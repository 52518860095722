import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Common from '../Common/common';
import CommonTrain from '../Common/common_train';
import constClass from '../../Constants/Constants';


const TrainBookingList = (props) => {
  const { user, settings } = props;
  const { bookDate, trainNo } = useParams();
  const [stopReason, setStopReason] = useState("");
  const [binData, setBinData] = useState(null);
  const componentRef = useRef();
  const refreshData = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    var reg_params = {};
    reg_params = {
      "operator": "and",
      "where": [
        { "site_id": props.siteId },
        { "book_date": new Date(moment(bookDate, 'YYYYMMDD').format('YYYY-MM-DD')) },
        { "train_no": trainNo },
      ]
    }
    //console.log(user)
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar_detail/searchbin/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setBinData(data);
  }, [props.siteId, bookDate, trainNo])

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const handleSubmitClick = async () => {
    const jwt = localStorage.getItem('jwt');
    if (window.confirm(`予約受付を${binData[0].book_status === constClass.FLAG.OFF ? "再開" : "停止"}しますか？`)) {
      var data = {
        site_id: props.match.params.siteId,
        book_date: moment(bookDate).format("YYYY-MM-DD"),
        train_no: trainNo,
        book_status: binData[0].book_status === constClass.FLAG.OFF ? constClass.FLAG.ON : constClass.FLAG.OFF,
        book_stop_message: stopReason,
        upd_name: user.userId,
      };
      const posturl = (binData[0].book_status === constClass.FLAG.OFF) ? `${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/restart/` : `${process.env.REACT_APP_BACKEND_URL}/train_calendar_bin/allstop/`
      var res = await axios.post(posturl, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res.data.error) {
        window.alert(res.data.message);
      } else {
        window.alert(`予約受付を${binData[0].book_status === constClass.FLAG.OFF ? "再開" : "停止"}しました`);
        if(binData[0].book_status === constClass.FLAG.OFF)  setStopReason("");
        await refreshData();
      }
    }
  }
  const handleChangeStopReason = (e) => {
    const target = e.target;
    setStopReason(target.value);
  }
  const stopReasonItem = () => {
    const items = [];
    var list = JSON.parse(Common.getSettingValue(settings, "STOP_REASON_LIST", []));
    items.push(<option value="" key={"-"}>- 停止理由を選択してください。 -</option>)
    list.forEach((data, idx) => {
      items.push(<option value={data} key={idx}>{data}</option>)
    });
    return items;
  }
  const renderList = () => {
    return (
      <div ref={componentRef} className="print-list">
        <div className="row mb-0 p-0 ">
          <div className="col-2 pl-0 text-left align-self-end">
            <h3>{moment(bookDate, 'YYYYMMDD').format('YYYY/MM/DD')}</h3>
          </div>
          <div className="col-4 pl-0 text-left align-self-end">
            <h3>列車番号：{trainNo}</h3>
          </div>
        </div>
        <div className="row mb-0 p-3 ">
          {(binData.length > 0) && 
            <> <div className="col-1 text-center align-self-center">
              <span>停止理由</span>
            </div>
              <div className="col-4 text-center align-self-center">
              {binData[0].book_status === constClass.FLAG.ON ? 
                <select className="custom-select w-100" aria-label="停止理由" id="stop_reason" name="stop_reason" value={stopReason} onChange={handleChangeStopReason}>
                  {stopReasonItem()}
                </select>
              :  binData[0].book_stop_message
              }
              </div></>
          }
          {(binData.length >0 && moment(`${bookDate} ${("0000" + binData[binData.length-1].getoff_time).slice(-4)}`,'YYYYMMDD kmm').isAfter(moment())) && 
          <div className="col-3 pl-0 text-right align-self-end">
            <button className={`btn btn-${binData[0].book_status === constClass.FLAG.OFF ? 'primary' : 'danger'} px-3`}
              onClick={handleSubmitClick}
              disabled={(binData[0].book_status === constClass.FLAG.ON && !stopReason)}
            >
              {(binData.length > 0 && binData[0].book_status === constClass.FLAG.OFF) ? "予約再開" : "予約中止"}
            </button>
          </div>}
        </div>
        <div className="row mb-3 p-0">
          <div className="col-8 p-0">
            <table className="table table-bordered table-striped" height="1">
              <thead>
                <tr>
                  <th className="text-center align-middle like-button">
                    駅
                  </th>
                  <th className="text-center align-middle like-button">
                    定刻
                  </th>
                  <th className="text-center align-middle like-button">
                    降車
                  </th>
                  <th className="text-center align-middle like-button">
                    乗車
                  </th>
                  <th className="text-center align-middle like-button">
                    乗車数
                  </th>
                  <th className="text-center align-middle like-button">
                    残数
                  </th>
                </tr>
              </thead>
              <tbody>
                {binData.length <= 0 && <tr><td className="text-center align-middle p-3" colSpan={6}>データが取得できませんでした</td></tr>}
                {binData.map((data, idx) => (
                  <tr key={idx}>
                    {/* 駅名 */}
                    <td className="text-center align-middle p-1">
                      {data.ride_station_name}
                    </td>
                    {/* 定刻 */}
                    <td className="text-center align-middle p-1">
                      {CommonTrain.strTotime(data.ride_time)}
                    </td>
                    {/* 降車 */}
                    <td className="text-center align-middle p-1">
                      {idx === 0 ? "-" : binData[idx - 1].count_getoff}
                    </td>
                    {/* 乗車 */}
                    <td className="text-center align-middle p-1">
                      {data.count_ride}
                    </td>
                    {/* 乗車数 */}
                    <td className="text-center align-middle p-1">
                      {data.count_riding}
                    </td>
                    {/* 残数 */}
                    <td className="text-center align-middle p-1">
                      {(data.book_count - data.count_riding)}
                    </td>
                  </tr>
                ))
                }
                {/* 終点 */}
                {binData.length > 0 &&
                  <tr key={binData.length - 1}>
                    {/* 駅名 */}
                    <td className="text-center align-middle p-1">
                      {binData[binData.length - 1].getoff_station_name}
                    </td>
                    {/* 定刻 */}
                    <td className="text-center align-middle p-1">
                      {CommonTrain.strTotime(binData[binData.length - 1].getoff_time)}
                    </td>
                    {/* 降車 */}
                    <td className="text-center align-middle p-1">
                      {binData[binData.length - 1].count_getoff}
                    </td>
                    {/* 乗車 */}
                    <td className="text-center align-middle p-1">
                      -
                    </td>
                    {/* 乗車数 */}
                    <td className="text-center align-middle p-1">
                      -
                    </td>
                    {/* 残数 */}
                    <td className="text-center align-middle p-1">
                      -
                    </td>
                  </tr>

                }
              </tbody>
            </table>
          </div>
        </div>
      </div >
    )
  }
  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(binData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(props.user)}</div></div>
      {binData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {binData !== null && renderList()}
    </div>
  )
}

export default TrainBookingList;
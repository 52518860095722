import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';

const BackyardUser = (props) => {
  const { user } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [searchData, setSearchData] = useState(null);

  const refreshUser = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      "operator": "and",
      "where": [
        { "site_id" : props.match.params.siteId },
        { "shop_id" : props.user.shopId }
      ]
    }
    const rs = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/search/`, params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    rs.sort((a, b) => a.user_id < b.user_id ? -1 : 1);
    setSearchData(rs);
  },[props.user.shopId, props.match.params.siteId]);



  const editButtonClick = async (user_id) => {
    try {
      history.push(`${generatePath(`${props.match.path}user/edit/${user_id}`,{ siteId: props.match.params.siteId })}`);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } 
  }




  const editbutton = (data) => {
    return (
      < button type="button"
        className={`btn btn-primary mx-1`}
        onClick={() => editButtonClick(data.user_id)}
      >編集
      </button>
    )
  }

  const registerClick = () => {
    history.push(`${generatePath(`${props.match.path}user/edit/`,{ siteId: props.match.params.siteId })}`);
  }

  useEffect(() => {
    async function fetchData() {
      await refreshUser();
    }
    return fetchData();
  }, [refreshUser]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {searchData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {/* 新規登録ボタン */}
      <div className="col-sm-13 mb-3 text-right">
        <button className="btn btn-dark" onClick={registerClick}>ユーザー新規登録</button>
      </div>

      {searchData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center">
                    ユーザー
                  </td>
                  <td className="text-center">
                    編集
                  </td>
                </tr>
              </thead>
              <tbody>
                {searchData.map((data) => (
                  <tr key={data.user_id}>
                    {/* ユーザー */}
                    <td className="text-center align-middle">
                      {data.user_name}
                    </td>
                    {/* 編集 */}
                    <td className="text-center align-middle">
                      {editbutton(data)}

                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardUser